/** @jsxImportSource @emotion/react */
import { IconButton, Skeleton, TextField } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { NoData, PSButton, PSError, Text, PSSelect, PSApplicationCard, Icon, PSDialog, PSMenu, PSMenuItem } from '../../ui-kit';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { GraphQLClient } from 'graphql-request';
import { CreateCustomGenAiApplicationInput, GenAiApplicationAdditionStatus, GenAiApplicationsGlobalSettings, GenAiApplicationType, GetApplicationsGlobalSettingsQuery, RulesMode, SecurityApproach } from '../../gql/generated/graphql';
import { graphql, graphqlEndpoint } from '../../gql';
import AddVerifiedApplicationModal from './AddVerifiedApplicationModal';
import AddCustomApplicationDialog from './AddCustomApplicationDialog';
import GenAiMgmtEditApplicationRules from './GenAiMgmtEditApplicationRules';
import { useForm } from 'react-hook-form';
import { GenAiManageStyle } from './GenAiManage.css';
import { differenceBetweenObjects } from '../../utils';
import GenAiMgmtGlobalSettings from './GenAiMgmtGlobalSettings';
import { useRightDrawerContext } from '../../contexts';
import { createPortal } from 'react-dom';
import { useGraphQLMutation } from '../../hooks';
import SwitchGenAiMangeMode from './SwitchGenAiMangeMode';

const getAllApplications = graphql(`
    query GetAllApplicationsQuery($input: GetGenAiApplicationsInput!) {
        getGenAiApplications(input: $input) {
            genAiApplicationsWithAdditionStatus {
                additionStatus
                wasModified
                genAiApplication {
                    vendor
                    logo
                    isCustom
                    id
                    faviconUrl
                    name
                    domain
                    type
                }
            }
            securityApproach
            count
        }
    }
`);

const getApplicationsGlobalSettings = graphql(`
    query GetApplicationsGlobalSettings {
        getGenAiApplicationsGlobalSettings {
            ignoredGenAiApplicationIds
            canBypassBlock
            canBypassInspection
            inspectionAction
            logAction
            securityApproach
        }
    }
`)

const queryGetGenAiApplicationRules = graphql(`
    query GetGenAiApplicationRules($id: String!) {
        getGenAiApplicationRules(input: {id: $id}) {
            canBypassBlock
            canBypassInspection
            emails
            inspectionAction
            groups
            logAction
        }
    }
`);

const getIntegrations = graphql(`
    query GetIntegrations {
        integration {
            type
            isEnabled
        }
    }
`);

const mutateDeleteApplication = graphql(`
    mutation MutateDeleteApplication($id: String!) {
        removeGenAiApplicationRules(input: {id: $id})
    }
`);

const mutateAddCustomApplication = graphql(`
    mutation MutateAddCustomApplication($input: createCustomGenAiApplicationInput!) {
        createCustomGenAiApplication(input: $input) {
            id
        }
    }
`);

const mutateUpdateGlobalSettings = graphql(`
    mutation UpdateGlobalSettings($input: GenAiApplicationsGlobalSettings!) {
        setGenAiApplicationsGlobalSettings(input: $input) {
            canBypassBlock
            inspectionAction
            canBypassInspection
            logAction
            securityApproach
            ignoredGenAiApplicationIds
        }
    }
`);

const mutateCreateApplicationRule = graphql(`
    mutation CreateApplicationRule($input: [createGenAiApplicationRuleInput!]!) {
        createGenAiApplicationRule(input: $input)
    }
`)

type IProps = {};

const GenAiManage: React.FC<IProps> = (props) => {
    const { } = props;

    const queryClient = useQueryClient();
    const [AddVerifiedApplicationModalOpenStatus, setAddVerifiedApplicationModalOpenStatus] = useState<'verified' | 'custom' | null>(null);

    const [activeApplicationIndex, setActiveApplicationIndex] = useState<number>(0);

    const [isRemoveApplicationDialogOpen, setIsRemoveApplicationDialogOpen] = useState<boolean>(false);

    const [isSaveDialogOpen, setIsSaveDialogOpen] = useState(false);
    const [handleSaveChanges, setHandleSaveChanges] = useState<() => void>(() => () => { });
    const [handleCancelSaveChanges, setHandleCancelSaveChanges] = useState<() => void>(() => { });
    const [isApplicationFormDirty, setIsApplicationFormDirty] = useState(false);

    const [simpleAdvancedAnchorEl, setSimpleAdvancedAnchorEl] = useState<null | HTMLElement>(null);

    const { initDrawer, resetDrawer, wrapperDomNode } = useRightDrawerContext();

    useEffect(() => {
        initDrawer({
            width: 630,
            state: 'OPEN'
        });
        return () => resetDrawer();
    }, [])


    const { mutateAsync: createApplicationsRules } = useMutation({
        mutationFn: async (ids: Array<string>) => {
            const client = new GraphQLClient(graphqlEndpoint);
            const { createGenAiApplicationRule } = await client.request(mutateCreateApplicationRule, {
                input: ids.map(id => ({ id }))
            });

            return createGenAiApplicationRule;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['appsNotToTrack'] });
            queryClient.invalidateQueries({ queryKey: ['getAllApplications'] });
        }
    })

    const { mutateAsync: removeApplicationAsync, isPending: RemoveApplicationLoading } = useMutation({
        mutationFn: async (id: string) => {
            const client = new GraphQLClient(graphqlEndpoint);
            const { removeGenAiApplicationRules } = await client.request(mutateDeleteApplication, { id });
            return removeGenAiApplicationRules;
        },
        onSuccess: () => {
            if (activeApplicationIndex === (applicationsData?.genAiApplicationsWithAdditionStatus?.length || -1) - 1) {
                setActiveApplicationIndex(activeApplicationIndex - 1);
            }
            queryClient.invalidateQueries({ queryKey: ['appsNotToTrack'] });
            queryClient.invalidateQueries({ queryKey: ['getAllApplications'] });
        }
    });

    const { mutateAsync: addCustomApplication } = useMutation({
        mutationFn: async (variables: CreateCustomGenAiApplicationInput) => {
            const client = new GraphQLClient(graphqlEndpoint);
            const { createCustomGenAiApplication } = await client.request(mutateAddCustomApplication, {
                input: {
                    ...variables
                }
            });
            return createCustomGenAiApplication;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['appsNotToTrack'] });
            queryClient.invalidateQueries({ queryKey: ['getAllApplications'] });
        }
    })

    const { mutateAsync: updateGlobalSettings, isPending: isUpdateGlobalSettingsLoading } = useMutation({
        mutationFn: async (variables: GenAiApplicationsGlobalSettings) => {
            const variablesToUpdate = differenceBetweenObjects(globalSettings!, variables);

            const client = new GraphQLClient(graphqlEndpoint);
            const { setGenAiApplicationsGlobalSettings } = await client.request(mutateUpdateGlobalSettings, {
                input: {
                    ...variablesToUpdate
                }
            });
            return setGenAiApplicationsGlobalSettings;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['appsNotToTrackByIds'] });
            queryClient.invalidateQueries({ queryKey: ['appsNotToTrack'] });
            queryClient.invalidateQueries({ queryKey: ['getApplicationsGlobalSettings'] });
            queryClient.invalidateQueries({ queryKey: ['getAllApplications'] });
            queryClient.invalidateQueries({ queryKey: ['getGenAiApplicationRules', activeApplication?.genAiApplication.id] });
        }
    })

    const { data: applicationsData, isLoading: isApplicationsLoading, isError: isApplicationsError, isSuccess: isApplicationSuccess } = useQuery({
        queryKey: ['getAllApplications'],
        queryFn: async ({ signal }) => {
            const client = new GraphQLClient(graphqlEndpoint, { signal });
            const { getGenAiApplications } = await client.request(getAllApplications, {
                input: {
                    Pagination: {
                        pageNumber: 1,
                        pageSize: 200
                    },
                    Filter: {
                        additionStatus: [GenAiApplicationAdditionStatus.AddedAsException],
                        applicationType: [GenAiApplicationType.AiApplication, GenAiApplicationType.AiComponent]
                    }
                }
            });
            return getGenAiApplications;
        }
    })
    const activeApplication = applicationsData?.genAiApplicationsWithAdditionStatus?.[activeApplicationIndex];

    const { control: applicationControl, reset: applicationFormReset, formState: applicationFormState, getValues: getApplicationFormValues, setValue: setApplicationFormValue } = useForm<GetApplicationsGlobalSettingsQuery['getGenAiApplicationsGlobalSettings']>()


    const { data: globalSettings } = useQuery({
        queryKey: ['getApplicationsGlobalSettings'],
        queryFn: async ({ signal }) => {
            const client = new GraphQLClient(graphqlEndpoint, { signal });
            const { getGenAiApplicationsGlobalSettings } = await client.request(getApplicationsGlobalSettings);
            applicationFormReset(getGenAiApplicationsGlobalSettings);
            return getGenAiApplicationsGlobalSettings;
        }
    })

    const { data: applicationRules } = useQuery({
        queryKey: ['getGenAiApplicationRules', activeApplication?.genAiApplication.id],
        queryFn: async ({ signal }) => {
            const applicationId = activeApplication?.genAiApplication.id;
            if (!applicationId) return;
            const client = new GraphQLClient(graphqlEndpoint, { signal });
            const { getGenAiApplicationRules } = await client.request(queryGetGenAiApplicationRules, {
                id: applicationId
            });

            if (!getGenAiApplicationRules) return;

            const { canBypassBlock, canBypassInspection, inspectionAction, logAction, emails, groups } = getGenAiApplicationRules;

            return {
                emails: emails || [],
                groups: groups || [],
                canBypassBlock: canBypassBlock,
                canBypassInspection: canBypassInspection,
                inspectionAction: inspectionAction,
                logAction: logAction
            };
        },
        enabled: !!activeApplication?.genAiApplication.id
    })

    const { data: isIdentityIntegrationActive } = useQuery({
        queryKey: ['getIntegrations'],
        queryFn: async ({ signal }) => {
            const client = new GraphQLClient(graphqlEndpoint, { signal });
            const { integration } = await client.request(getIntegrations);
            const identityTypes = ['OKTA', 'ENTRA'];
            return integration.flatMap(x => ({ type: x.type, isEnabled: x.isEnabled })).some(x => identityTypes.includes(x.type) && x.isEnabled);
        }
    })

    const [isChangeSecurityApproachDialogOpen, setIsChangeSecurityApproachDialogOpen] = useState<boolean>(false);
    const [changeSecurityApproachDialogValidation, setChangeSecurityApproachDialogValidation] = useState<string>('');
    const changeSecurityApproach = async (value: SecurityApproach) => {
        await updateGlobalSettings({ securityApproach: value });
    }

    const handleApplicationClick = (index: number) => {
        if (isApplicationFormDirty) {
            setHandleSaveChanges((prev) => {
                return () => {
                    prev();
                    switchApplication(index);
                }
            });
            setHandleCancelSaveChanges(() => {
                return () => {
                    switchApplication(index);
                    setIsSaveDialogOpen(false);
                }
            })
            setIsSaveDialogOpen(isApplicationFormDirty);
        } else {
            switchApplication(index);
        }
    }

    const switchApplication = (index: number) => {
        setActiveApplicationIndex(index);
    }

    const handleOpenAddVerifiedApplicationModal = (type: 'verified' | 'custom') => {
        setAddVerifiedApplicationModalOpenStatus(type);
    }

    const removeApplication = async () => {
        await removeApplicationAsync(applicationsData?.genAiApplicationsWithAdditionStatus![activeApplicationIndex]?.genAiApplication?.id!);
    }

    const AddApplicationButton = useCallback(() => (
        <PSButton
            onClick={() => handleOpenAddVerifiedApplicationModal('verified')}
            variant='flat'
            iconName='AddRounded'
        >
            Add Application {applicationsData?.securityApproach === SecurityApproach.InspectAll ? 'to block' : 'to approve'}
        </PSButton>
    ), [applicationsData])

    const RemoveApplicationButton = () => (
        <PSButton
            onClick={() => setIsRemoveApplicationDialogOpen(true)}
            isLoading={RemoveApplicationLoading}
            iconName='DeleteRounded'
            variant='flat'
        >
            Remove Application
        </PSButton>
    )

    const ApplicationError = () => (
        <div css={GenAiManageStyle.statesContainer}>
            <PSError message='Failed to fetch applications' />
        </div>
    )

    const ApplicationNoData = useCallback(() => (
        <div css={GenAiManageStyle.statesContainer}>
            <NoData message='Add application' />
            <AddApplicationButton />
        </div>
    ), [AddApplicationButton])

    return (
        <div css={GenAiManageStyle.self}>
            <div css={GenAiManageStyle.leftSectionContainer}>

                {isApplicationsLoading && (
                    <React.Fragment>
                        <Skeleton variant='rectangular' width={'70%'} sx={{ minHeight: '33px', borderRadius: '4px' }} />
                        <Skeleton variant='rectangular' width={'90%'} sx={{ minHeight: '20px', borderRadius: '4px' }} />
                        <Skeleton variant='rectangular' width={'50%'} sx={{ minHeight: '40px', borderRadius: '4px' }} />

                        <div css={css`display: flex; flex-direction: column; gap: 10px; overflow-y: auto; padding: 5px; overflow-y: hidden;`}>
                            {Array(20).fill(undefined).map((_, index) => (
                                <PSApplicationCard isLoading={true} key={index} />
                            ))}
                        </div>
                    </React.Fragment>
                )}

                {isApplicationsError && <PSError message='Failed to fetch applications' />}

                {isApplicationSuccess && (
                    <React.Fragment>
                        <div css={css`display: flex; flex-direction: column, align-items: center; flex-wrap: wrap; gap: 5px;`}>
                            <div css={css`display: flex; flex: 1; align-items: center; gap: 5px;`}>
                                <Icon iconName='VerifiedUserOutlined' />
                                <Text variant='bold'>Security Approach:</Text>

                                <div css={css`margin-left: auto;`}>
                                    <SwitchGenAiMangeMode />
                                </div>
                            </div>

                            <PSSelect
                                fullWidth
                                value={applicationsData.securityApproach}
                                options={[
                                    { label: 'Approve All genAI Apps', value: SecurityApproach.InspectAll },
                                    { label: 'Block All genAI Apps', value: SecurityApproach.BlockAll }
                                ]}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                                paperMargin={'0'}
                                description='Be advised: Switching modes significantly changes user interaction with genAI apps.'
                                onChange={(event) => {
                                    event.target.value !== applicationsData.securityApproach && setIsChangeSecurityApproachDialogOpen(true);
                                }}
                            />
                        </div>
                        <div css={css`margin-top: 15px;`}>
                            <AddApplicationButton />
                        </div>
                        <div css={GenAiManageStyle.applicationsListContainer}>
                            {applicationsData?.genAiApplicationsWithAdditionStatus?.length === 0 && (
                                <div css={css`margin-top: 60px;`}>
                                    <NoData message='Add application' />
                                </div>
                            )}

                            {applicationsData?.genAiApplicationsWithAdditionStatus && applicationsData?.genAiApplicationsWithAdditionStatus?.length > 0 && applicationsData?.genAiApplicationsWithAdditionStatus?.map((x, index) => {
                                const { id, domain, isCustom, name, logo, faviconUrl, type } = x.genAiApplication;
                                return (
                                    <PSApplicationCard
                                        isActive={index === activeApplicationIndex}
                                        key={id}
                                        isCustom={isCustom}
                                        isAiComponent={type === GenAiApplicationType.AiComponent}
                                        type={x.wasModified ? 'modified' : 'all'}
                                        imageUrl={isCustom ? `https://www.${domain}/favicon.ico` : (logo || faviconUrl || undefined)}
                                        appName={name}
                                        onClick={() => handleApplicationClick(index)}
                                    />
                                )
                            })}
                        </div>
                    </React.Fragment>
                )}
            </div>

            <div css={GenAiManageStyle.rightSectionContainer}>
                {globalSettings && createPortal(<GenAiMgmtGlobalSettings
                    key={JSON.stringify(globalSettings)}
                    onSaveClick={(shouldOverride) => updateGlobalSettings({
                        ...getApplicationFormValues(),
                        applyToExistingRules: shouldOverride
                    })}
                    onCancelClick={() => applicationFormReset()}
                    applicationFormState={applicationFormState}
                    isSaveButtonLoading={isUpdateGlobalSettingsLoading}
                    globalSettings={globalSettings}
                    applicationControl={applicationControl}
                    setApplicationFormValue={setApplicationFormValue}
                />, wrapperDomNode!)}

                <div css={GenAiManageStyle.applicationsContentContainer}>
                    {isApplicationsError ? (
                        <ApplicationError />
                    ) : isApplicationSuccess && applicationsData?.genAiApplicationsWithAdditionStatus?.length === 0 ? (
                        <ApplicationNoData />
                    ) : (
                        <React.Fragment>
                            {applicationRules && globalSettings &&
                                <GenAiMgmtEditApplicationRules
                                    key={activeApplication?.genAiApplication.id + JSON.stringify(applicationRules) + JSON.stringify(globalSettings)}
                                    RemoveApplicationButton={RemoveApplicationButton}
                                    applicationRules={applicationRules as any}
                                    activeApplication={activeApplication!}
                                    applicationsData={applicationsData!}
                                    isIdentityIntegrationActive={isIdentityIntegrationActive}
                                    globalSettings={globalSettings}
                                    setHandleSaveChanges={setHandleSaveChanges}
                                    setIsApplicationFormDirty={setIsApplicationFormDirty}
                                />
                            }
                        </React.Fragment>
                    )}
                </div>
            </div>

            {isRemoveApplicationDialogOpen && <PSDialog
                title='Remove Application'
                open={isRemoveApplicationDialogOpen}
                onClose={() => setIsRemoveApplicationDialogOpen(false)}
                actionButtonText='Remove'
                actionButtonVariantType='critical'
                action={removeApplication}
            >
                <Text inline>Are you sure you want to remove</Text> <Text variant='bold' inline>{activeApplication?.genAiApplication.name}?</Text>
                <Text textCss={css`margin-top: 5px;`} color='black-70'>This will remove all settings for this application.</Text>
            </PSDialog>}

            {isSaveDialogOpen && <PSDialog
                open={isSaveDialogOpen}
                onClose={handleCancelSaveChanges}
                title='Unsaved Changes'
                actionButtonText='Save and Leave'
                cancelButtonText='Leave without saving'
                action={handleSaveChanges}
                preventEscapeAndBackdropClose
            >
                <Text>looks like you have unsaved changes?</Text>
                <Text textCss={css`margin-top: 5px;`} variant='bold'>Are you sure you want to proceed without saving</Text>
            </PSDialog>}

            {isChangeSecurityApproachDialogOpen && <PSDialog
                title='Change your genAI security approach'
                open={isChangeSecurityApproachDialogOpen}
                onClose={() => {
                    setIsChangeSecurityApproachDialogOpen(false);
                    setChangeSecurityApproachDialogValidation('');
                }}
                actionButtonText='Change'
                isActionDisabled={changeSecurityApproachDialogValidation !== 'DELETE'}
                action={() => changeSecurityApproach(applicationsData?.securityApproach === SecurityApproach.InspectAll ? SecurityApproach.BlockAll : SecurityApproach.InspectAll)}
            >
                <div>
                    <Text inline>You are about to change your security approach from </Text>
                    <Text color={applicationsData?.securityApproach === SecurityApproach.InspectAll ? 'blue' : 'red'} variant='bold' inline>{applicationsData?.securityApproach === SecurityApproach.InspectAll ? 'Approve All genAI Apps' : 'Block All genAI Apps'}</Text>
                    <Text inline> to </Text>
                    <Text color={applicationsData?.securityApproach === SecurityApproach.InspectAll ? 'red' : 'blue'} variant='bold' inline>{applicationsData?.securityApproach === SecurityApproach.InspectAll ? 'Block All genAI Apps' : 'Approve All genAI Apps'}</Text>
                </div>

                <Text variant='bold' textCss={css`margin-top: 20px;`} color='black-70'>This will reset all your settings for all applications.</Text>

                <Text textCss={css`margin-top: 20px;`}>Type "<b>DELETE</b>" to continue</Text>

                <TextField
                    size='small'
                    variant='outlined'
                    placeholder='Enter...'
                    value={changeSecurityApproachDialogValidation}
                    onChange={(e) => setChangeSecurityApproachDialogValidation(e.target.value)}
                    onPaste={(e) => e.preventDefault()}
                    fullWidth
                    css={css`margin-top: 20px;`}
                />
            </PSDialog>}

            {/* {AddVerifiedApplicationModalOpenStatus === 'custom' && <AddCustomApplicationDialog
                isOpen={AddVerifiedApplicationModalOpenStatus === 'custom'}
                closeDialog={() => setAddVerifiedApplicationModalOpenStatus(null)}
                addCustomApp={(name, domain, createRule) => addCustomApplication({ name, domain })}
                addAppAlreadyExists={(appExists) => { createApplicationsRules([appExists.genAiApplication.id]) }}
            />} */}
            {AddVerifiedApplicationModalOpenStatus === 'verified' && <AddVerifiedApplicationModal
                // openCustomAppModal={() => setAddVerifiedApplicationModalOpenStatus('custom')}
                isOpen={AddVerifiedApplicationModalOpenStatus === 'verified'}
                onClose={() => setAddVerifiedApplicationModalOpenStatus(null)}
            />}
        </div >
    )
}

export default GenAiManage;
