/** @jsxImportSource @emotion/react */
import { Autocomplete, Chip, CircularProgress, PaperProps, TextField } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { PSMenuItem, PSPaper, GenAiApplication } from '../../ui-kit';
import { Controller, Control, UseFormSetValue } from 'react-hook-form';
import { GenAiApplicationAdditionStatus, GenAiApplicationType, GetApplicationsGlobalSettingsQuery } from '../../gql/generated/graphql';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { graphql, graphqlEndpoint } from '../../gql';
import { GraphQLClient } from 'graphql-request';
import AddCustomApplicationDialog from './AddCustomApplicationDialog';

const getGenAiApps = graphql(`
    query GetGenAiApps($input: GetGenAiApplicationsInput!) {
        getGenAiApplications(input: $input) {
            genAiApplicationsWithAdditionStatus {
                genAiApplication {
                    logo
                    id
                    faviconUrl
                    name
                    domain
                    isCustom
                }
            }
        }
    }
`);

const mutateAddCustomApplication = graphql(`
    mutation MutateAddCustomApplication($input: createCustomGenAiApplicationInput!) {
        createCustomGenAiApplication(input: $input) {
            id
        }
    }
`);

type IProps = {
    globalSettings: NonNullable<GetApplicationsGlobalSettingsQuery['getGenAiApplicationsGlobalSettings']>;
    applicationControl: Control<GetApplicationsGlobalSettingsQuery['getGenAiApplicationsGlobalSettings'], any>;
    setApplicationFormValue: UseFormSetValue<GetApplicationsGlobalSettingsQuery['getGenAiApplicationsGlobalSettings']>;
};

const AppsNotToTrack: React.FC<IProps> = (props) => {
    const { globalSettings, applicationControl, setApplicationFormValue } = props;

    const queryClient = useQueryClient();
    const [isCustomAppDialogOpen, setIsCustomAppDialogOpen] = React.useState(false);

    const { data: appsData, isLoading: isAppsLoading } = useQuery({
        queryKey: ['appsNotToTrack'],
        queryFn: async ({ signal }) => {
            const client = new GraphQLClient(graphqlEndpoint, { signal });
            const { getGenAiApplications } = await client.request(getGenAiApps, {
                input: {
                    Pagination: {
                        pageNumber: 1,
                        pageSize: 1000,
                    },
                    Filter: {
                        additionStatus: [GenAiApplicationAdditionStatus.NotAdded],
                        applicationType: [GenAiApplicationType.AiApplication, GenAiApplicationType.AiComponent]
                    },
                },
            });
            return getGenAiApplications.genAiApplicationsWithAdditionStatus || [];
        },
        refetchOnMount: true,
    });

    const { data: appsByIdsData, isLoading: isAppsByIdsLoading } = useQuery({
        queryKey: ['appsNotToTrackByIds'],
        queryFn: async ({ signal }) => {
            const client = new GraphQLClient(graphqlEndpoint, { signal });
            const { getGenAiApplications } = await client.request(getGenAiApps, {
                input: {
                    Filter: {
                        applicationsId: globalSettings.ignoredGenAiApplicationIds,
                        applicationType: [GenAiApplicationType.AiApplication, GenAiApplicationType.AiComponent]
                    },
                    Pagination: {
                        pageNumber: 1,
                        pageSize: globalSettings.ignoredGenAiApplicationIds.length,
                    },
                }
            });
            return getGenAiApplications;
        },
        select: (data) => data?.genAiApplicationsWithAdditionStatus || [],
        enabled: globalSettings.ignoredGenAiApplicationIds.length > 0,
        refetchOnMount: true,
    });

    const mutateAddCustomApp = useMutation({
        mutationFn: async (input: { name: string; domain: string }) => {
            const client = new GraphQLClient(graphqlEndpoint);
            const { createCustomGenAiApplication } = await client.request(mutateAddCustomApplication, { input });
            const customApplicationId = createCustomGenAiApplication?.id;
            if (!customApplicationId) return;
            setApplicationFormValue('ignoredGenAiApplicationIds', [...globalSettings.ignoredGenAiApplicationIds, customApplicationId], {
                shouldDirty: true,
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['appsNotToTrack'] });
            setIsCustomAppDialogOpen(false);
        }
    })

    const data = useMemo(() => [...(appsByIdsData || []), ...(appsData || [])].filter((genAiApplication, index, currentArr) => currentArr.findIndex((genAiApplicationCompare) => genAiApplicationCompare.genAiApplication.id === genAiApplication.genAiApplication.id) === index), [appsByIdsData, appsData, globalSettings.ignoredGenAiApplicationIds]);
    const isLoading = isAppsLoading || isAppsByIdsLoading;
    const applicationsIds = useMemo(() => data.map((x) => x.genAiApplication.id), [data]);

    const getApplicationById = useCallback((id: string) => data.find((x) => x.genAiApplication.id === id), [data]);

    const getApplicationLabel = useCallback(
        (id: string) => {
            const app = getApplicationById(id);
            return (
                <GenAiApplication value={{ ...app?.genAiApplication, name: app?.genAiApplication.name }} iconSize={20} includeAppDomain />
            );
        },
        [getApplicationById]
    );

    const addCustomApplication = async (name: string, domain: string) => {
        await mutateAddCustomApp.mutateAsync({ name, domain });
    }

    const addAppAlreadyExists = (application: NonNullable<ReturnType<typeof getApplicationById>>) => {
        setApplicationFormValue('ignoredGenAiApplicationIds', [...globalSettings.ignoredGenAiApplicationIds, application.genAiApplication.id], {
            shouldDirty: true,
        });
    }

    return (
        <>
            <Controller
                name='ignoredGenAiApplicationIds'
                control={applicationControl}
                defaultValue={globalSettings.ignoredGenAiApplicationIds}
                render={({ field }) => (
                    <Autocomplete
                        {...field}
                        multiple
                        disableCloseOnSelect
                        options={applicationsIds}
                        onChange={(_, value) => field.onChange(value)}
                        filterOptions={(options, { inputValue }) =>
                            options.filter((option) => {
                                const application = getApplicationById(option);
                                return (
                                    application?.genAiApplication?.name.toLowerCase().includes(inputValue.toLowerCase()) ||
                                    application?.genAiApplication?.domain.toLowerCase().includes(inputValue.toLowerCase())
                                );
                            })
                        }
                        renderOption={(props, option, { selected }) => (
                            <PSMenuItem {...props} key={option} type='checkbox' selected={selected}>
                                {getApplicationLabel(option)}
                            </PSMenuItem>
                        )}
                        renderTags={(value, getTagProps) =>
                            value.map((optionId, index) => (
                                <Chip
                                    {...getTagProps({ index })}
                                    variant='outlined'
                                    size='small'
                                    label={getApplicationLabel(optionId)}
                                    key={optionId}
                                />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant='outlined'
                                placeholder='Add genAi apps'
                                size='small'
                                onKeyDown={(e) => e.key === 'Backspace' && e.stopPropagation()}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {isLoading && <CircularProgress color="inherit" size={20} />}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                            />
                        )}
                        PaperComponent={AddNewCustomApp}
                        slotProps={{
                            paper: {
                                //@ts-ignore
                                setIsCustomAppDialogOpen: setIsCustomAppDialogOpen,
                            }
                        }}
                    />
                )}
            />

            <AddCustomApplicationDialog
                isOpen={isCustomAppDialogOpen}
                closeDialog={() => setIsCustomAppDialogOpen(false)}
                addCustomApp={addCustomApplication}
                addAppAlreadyExists={addAppAlreadyExists}
            />
        </>
    );
};

export default AppsNotToTrack;

type AddNewCustomAppProps = PaperProps & {
    setIsCustomAppDialogOpen?: React.Dispatch<React.SetStateAction<boolean>>
}

const AddNewCustomApp: React.FC<AddNewCustomAppProps> = (props) => {
    const { children, setIsCustomAppDialogOpen } = props;
    return (
        <PSPaper>
            {children}
            <PSMenuItem onClick={() => setIsCustomAppDialogOpen!(true)} onMouseDown={(event) => { event.preventDefault(); }} type='custom' iconName='AddRounded'>
                Add New Custom App
            </PSMenuItem>
        </PSPaper>
    )
}