import React, { useState, useMemo } from "react";
import {
    GridRowsProp,
    DataGrid,
    GridColDef,
    GridRowId,
    GridRowModesModel,
    GridRowModes,
    GridEventListener,
    GridRowEditStopReasons,
    GridRowModel,
    GridRenderCellParams,
} from "@mui/x-data-grid";

import { request } from 'graphql-request';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { graphql, graphqlEndpoint } from '../../gql';
import { Box } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { ApplicationTypes } from '../../gql/generated/graphql';
import { PopupMessageActionVerification } from "../../components/PopupMessageActionVerification";
import { PSButton, Text } from "../../ui-kit";
import { css } from '@emotion/react';

const queryApplications = graphql(`
    query Applications {
        applications {
            name
            applicationType
        }
    }
`);

const mutationUpdateApplication = graphql(`
    mutation UpdateApplication($oldName: String!, $newName: String!) {
        updateApplication(
            input: {
                oldName: $oldName
                newName: $newName
            }
        ) {
            name
        }
    }
`);

const mutationDeleteApplication = graphql(`
    mutation DeleteApplication($name: String!) {
        deleteApplication(name: $name) {
            name
        }
    }
`);

type ApplicationsViewerProps = {
    handlePopupMessage: (title: string, test: string) => void
}

export const ApplicationsViewer = ({ handlePopupMessage }: ApplicationsViewerProps) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const [popupText, setPopupText] = useState<string>('');
    const [popupTitle, setPopupTitle] = useState<string>('');
    const [appName, setAppName] = useState<string>();
    const [openPopupMessageVerification, setOpenPopupMessageVerification] = useState<boolean>(false);

    const { data: applications } = useQuery(
        {
            queryKey: ["applications"],
            queryFn: async () => {
                const { applications } = await request(graphqlEndpoint, queryApplications);
                return applications
            }
        }
    );

    const updateApplication = useMutation({
        mutationFn: async (variables: any) => {
            await request(graphqlEndpoint, mutationUpdateApplication, variables);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['applications'] })
        },
        onError: (error) => {
            let errorMessage = 'Failed to add connector';
            if ((error as any).response.errors[0].message === 'Duplicate Name') {
                errorMessage = 'Duplicate connector name is not allowed';
            }
            handlePopupMessage('Adding Failed', errorMessage);
        },
    });

    const deleteApplication = useMutation({
        mutationFn: async (variables: any) => {
            await request(graphqlEndpoint, mutationDeleteApplication, variables);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['applications'] })
        },
    });

    const applicationsType = useMemo(() => {
        let applicationsType: Record<string, string> = {};
        if (applications) {
            for (const application of applications) {
                if (!(application.name in applicationsType)) {
                    applicationsType[application.name] = application.applicationType;
                }
            }
        }

        return applicationsType;
    }, [applications]);

    const handlePopupMessageVerificationClose = () => {
        setAppName(undefined);
        setOpenPopupMessageVerification(false);
    }

    const handlePopupMessageVerificationYes = () => {
        deleteApplication.mutate({ name: appName });
        setAppName(undefined);
        setOpenPopupMessageVerification(false);
    }

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (name: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [name]: { mode: GridRowModes.Edit } });
    };

    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const handleSaveClick = (name: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [name]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (name: GridRowId) => () => {
        setAppName(name as string);
        setPopupTitle('Delete Application');
        setPopupText(`You are about to delete the application '${name}'. Are you sure you want to delete it?`);
        setOpenPopupMessageVerification(true);
    };

    const handleManageRulesClick = (name: GridRowId) => () => {
        navigate(`/manage/connectors/${encodeURI(name as string)}/rules`)
    }

    const handleManageApplicationClick = (name: GridRowId) => () => {
        navigate(`/manage/connectors/${encodeURIComponent(name as string)}/integration`)
    }

    const handleCancelClick = (name: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [name]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    };

    const processRowUpdate = (newRow: GridRowModel, oldRow: GridRowModel) => {
        const { name, applicationType } = newRow;

        if (name.trim() === '') {
            handlePopupMessage('Editing Failed', 'Empty name is not allowed');
            return oldRow;
        }

        if (name !== oldRow.name) {
            updateApplication.mutate({ oldName: oldRow.name, newName: name });
        }

        return { name, applicationType } as GridRowModel;
    };

    const columns: GridColDef[] = [
        {
            field: "name",
            headerName: "Name",

            minWidth: 200,
            flex: 0.33,
            editable: true,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    params.value
                )
            }
        },
        {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            minWidth: 660,
            flex: 1,
            cellClassName: "actions",
            getActions: ({ id: name }) => {
                const isInEditMode = rowModesModel[name]?.mode === GridRowModes.Edit;
                const applicationType = applicationsType[name];
                const disableDelete = (applicationType === ApplicationTypes.Extension);
                if (isInEditMode) {
                    return [
                        <PSButton variant="square" iconName="EditRounded" onClick={handleSaveClick(name)}>Save</PSButton>,
                        <PSButton variant="square" iconName="CancelRounded" onClick={handleCancelClick(name)}>Cancel</PSButton>
                    ];
                }

                return [
                    <PSButton variant="square" iconName="SettingsApplicationsRounded" onClick={handleManageApplicationClick(name)}>Manage Connector</PSButton>,
                    <PSButton variant="square" iconName="TuneRounded" onClick={handleManageRulesClick(name)}>Manage Rules</PSButton>,
                    <PSButton variant="square" iconName="EditRounded" onClick={handleEditClick(name)}>Rename</PSButton>,
                    <PSButton variant="square" iconName="DeleteRounded" onClick={handleDeleteClick(name)} disabled={disableDelete}>Delete</PSButton>
                ];
            }
        }

    ];

    return (
        <Box>
            {
                applications &&
                <DataGrid
                    rows={applications as GridRowsProp}
                    getRowId={(row) => row.name}
                    columns={columns}
                    editMode="row"
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                    disableRowSelectionOnClick
                    hideFooterPagination
                    hideFooterSelectedRowCount
                    hideFooter
                    autoHeight
                />
            }
            <PopupMessageActionVerification open={openPopupMessageVerification} title={popupTitle} text={popupText} handlePopupMessageClose={handlePopupMessageVerificationClose} handlePopupMessageYes={handlePopupMessageVerificationYes} />
        </Box>
    )
}