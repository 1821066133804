/** @jsxImportSource @emotion/react */
import React from 'react';
import { IProtectionProps, ProtectionWrapper } from '../Common';
import TokenLimitationForm from './TokenLimitationForm';

const TokenLimitationProtection: React.FC<IProtectionProps> = (props) => {
    const { control, getValues, isAdvancedMode, connectorType } = props;

    if (connectorType === 'developers') return null;

    return (
        <ProtectionWrapper
            control={control}
            label="Token Limitation"
            getValues={getValues}
            description="Protect against denial of service or denial of wallet attacks"
            formName="Token Limitation"
            Component={TokenLimitationForm}
            isAdvancedMode={isAdvancedMode}
            summary={`Token Limit Protection is designed to analyze and restrict the number of tokens in a prompt or response. This feature serves multiple use cases:

1. Prevention of Denial of Wallet and Denial of Service (DoS): This feature prevents excessive token usage, which can lead to increased costs ('denial of wallet') or application downtime (DoS).
2. Token Count Visibility: This feature can be utilized to monitor the number of tokens processed by your genAI application, providing valuable insights into both prompt and response side operations.
3. Cost Control for Third-party genAI Applications: For organizations using commercial third-party genAI applications that charge per token, the Token Limit Protection feature is a useful tool for managing and controlling costs.`}
        />
    )
}

export default TokenLimitationProtection;