import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { request } from 'graphql-request';
import { graphql, graphqlEndpoint } from '../gql';
import { ApplicationTypes } from "../gql/generated/graphql";
import React from "react";

const queryApplications = graphql(`
    query Applications {
        applications {
            name
            applicationType
        }
    }
`);

export const ApplicationIntegration = () => {
    let { applicationName } = useParams();
    const { data: application } = useQuery(
        {
            queryKey: ["applications"],
            queryFn: async () => {
                const { applications } = await request(graphqlEndpoint, queryApplications);
                return applications
            },
            select: (data) => data.find(x => x.name === applicationName)
        }
    );
    const url = `/ps-config-app/?applicationName=${encodeURIComponent(applicationName as string)}&isExtension=${application?.applicationType === ApplicationTypes.Extension}&isCodeAssistant=${application?.applicationType === ApplicationTypes.CodeAssistant}`;

    return (
        <iframe title="PsConfig App" style={{ flex: '1 1 auto', border: 0 }} src={url} />
    )
}