import { useState } from "react";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { ExpandMore } from './IntegrationComponents/ExpandMore';
import { Avatar, IconButton, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const convertIsEnabledToText = (isEnabled: boolean) => {
    if (isEnabled === true) {
        return 'Enabled';
    }
    return 'Disabled';
}

type IntegrationCardProps = {
    integrationName: string;
    helpText: string;
    isEnabled: boolean | null;
    helpFile: string | null;
    children: JSX.Element;
    imgFile?: string;
};

export const IntegrationCard = ({ integrationName, helpText, isEnabled, helpFile, children, imgFile }: IntegrationCardProps) => {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card sx={{ paddingLeft: 2, marginBottom: 3, border: 1, borderColor: 'grey.400', borderRadius: '20px' }}>
            <CardHeader
                title={
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                        <Typography variant="h6" sx={{ my: 1 }}>
                            {integrationName}
                        </Typography>
                        {helpFile !== null && <Link to={helpFile} target="_blank">
                        <IconButton size='small' color='inherit' title='Click to open the integration guide' style={{ borderRadius: '50%' }}>
                            <HelpOutlineIcon />
                        </IconButton>
                        </Link>}
                    </div>
                }
                subheader={<Typography variant="body1" sx={{ my: 1 }}>{helpText}</Typography>}
                avatar={<Avatar variant="square" alt={integrationName} src={imgFile} sx={{marginRight: 1 }}/>}
            />
            <CardActions disableSpacing>
                {isEnabled !== null && <Typography sx={{ mx: 1.5, cursor: 'pointer' }} variant="body1" onClick={handleExpandClick}>
                    {convertIsEnabledToText(isEnabled)}
                </Typography>}
                <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </ExpandMore>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    {children}
                </CardContent>
            </Collapse>
        </Card>
    )
}