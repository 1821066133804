/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState } from 'react';
import { Divider, ListItemIcon, ListItemText, MenuItem, SelectChangeEvent } from '@mui/material';
import Popover from '../ui-kit/Popover/Popover';
import PSSelect from '../ui-kit/PSSelect/PSSelect';
import { PSButton, Tooltip, Text, Icon } from '../ui-kit';
import { NavigationMenuUserStyle } from './NavigationMenuUser.css'; import { useDescope, useUser } from '@descope/react-sdk';
import { useQuery } from '@tanstack/react-query';
import { clearCurrentTenant, getCurrentTenant, getEnvTenants, setCurrentTenant } from '../routes/ManageTenants/tenants';
import { useLocation, useNavigate } from 'react-router-dom';

type IProps = {
    isCollapsed: boolean;
};

const NavigationMenuUser: React.FC<IProps> = (props) => {
    const { isCollapsed } = props;

    const { user } = useUser();
    const { logout } = useDescope();
    const navigate = useNavigate();
    const location = useLocation();
    const [userName, setUserName] = useState('');
    const [userButtonAnchorEl, setUserButtonAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const selectedUserTenant = getCurrentTenant();

    const { data: userTenantsData = [] } = useQuery(
        {
            queryKey: ["userTenants"],
            queryFn: async () => {
                const tenants = await getEnvTenants(user);
                return tenants.sort((a, b) => a.localeCompare(b));
            },
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false
        }
    );

    const isUserMenuOpen = Boolean(userButtonAnchorEl);

    useEffect(() => {
        if (user.name) {
            setUserName(user.name);
        } else if (user.email) {
            setUserName(user.email.split("@", 2)[0])
        } else {
            setUserName("Profile");
        }
    }, [user])


    const openUserMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setUserButtonAnchorEl(event.currentTarget);
    };

    const closeUserMenu = () => {
        setUserButtonAnchorEl(null);
    };

    const handleTenantSelection = async (event: SelectChangeEvent<string>) => {
        if (selectedUserTenant !== event.target.value) {
            await fetch('/api/access/register-logout');
            setCurrentTenant(event.target.value);
            clearCurrentTenant();
            if (location.pathname === "/") {
                window.location.reload();
            } else {
                navigate('/');
            }
            await fetch('/api/access/register-login');
        }
    };

    const logOutUser = useCallback(() => {
        fetch('/kibana/auth/logout', {
            method: 'POST',
            headers: {
                'osd-version': '2.11.0',
                'osd-xsrf': 'osd-fetch',
                'Content-Type': 'application/json'
            }
        })
            .catch((error) => {
                console.error('Error logging out from Kibana:', error);
            })
            .finally(() => {
                fetch('/api/access/register-logout');
                logout();
                clearCurrentTenant();
                document.cookie = "dscsess=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT;";
            });
    }, [logout]);

    return (
        <React.Fragment>
            {user.roleNames?.includes('displayTenants') &&
                <Tooltip title={`${userName}'s tenant - ${selectedUserTenant}`} placement='right'>
                    <div style={{ textAlign: 'center', textOverflow: 'ellipsis', overflow: 'hidden', width: '100%' }}>
                        {isCollapsed ? '' : 'Tenant: '}{selectedUserTenant}
                    </div>
                </Tooltip>
            }
            <Tooltip title={isCollapsed ? userName : ''} placement='right'>
                <PSButton isActive={isUserMenuOpen} variant='flat' css={NavigationMenuUserStyle.userButton} iconName='AccountCircleOutlined' onClick={openUserMenu} className="navigation-user-button">
                    {isCollapsed ? '' : userName}
                </PSButton>
            </Tooltip>
            <Popover width={220} css={NavigationMenuUserStyle.userMenuPopover} open={isUserMenuOpen} anchorEl={userButtonAnchorEl} onClose={closeUserMenu}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div css={NavigationMenuUserStyle.userDetails}>
                    <Text variant='bold'>{userName}</Text>
                    {user.email && <Text ellipsis variant='small' color='black-70'>{user.email}</Text>}
                </div>
                <Divider />
                {userTenantsData.length > 1 && <>
                    <div css={NavigationMenuUserStyle.userTenants}>
                        <Text variant='bold'>Tenants</Text>
                        <PSSelect
                            onChange={(event) => handleTenantSelection(event as SelectChangeEvent<string>)}
                            value={selectedUserTenant}
                            options={userTenantsData?.map(tenant => ({
                                value: tenant,
                                label: tenant
                            }))} />
                    </div>
                    <Divider />
                </>}
                <MenuItem css={NavigationMenuUserStyle.logOutButton} onClick={logOutUser}>
                    <ListItemIcon>
                        <Icon iconSize={20} color='black-50' iconName='LogoutRounded' />
                    </ListItemIcon>
                    <ListItemText>
                        <Text>Log Out</Text>
                    </ListItemText>
                </MenuItem>
            </Popover>
        </React.Fragment>
    )
}

export default NavigationMenuUser;