import { AllProtectionKeys, TAllProtections, TDevelopers, TEmployees, THomeGrownApplications, TProtectionCategories } from "./protections_types";

export const tokenLimitToValidNumber = (value: string | number) => {
    const number = parseInt(value.toString());

    if (!number) return 0;

    if (number < 0) return 0;

    if (number > 32678) return 32678;

    if (number % 8 !== 0) return Math.floor(number / 8) * 8;

    return number;
}

export const connectorsToCatagories = (connector: THomeGrownApplications | TEmployees | TDevelopers): Record<TProtectionCategories, Set<typeof AllProtectionKeys[number]>> => {
    const protections: TAllProtections = {
        ...connector.prompt,
        ...(connector as any).response,
    }


    const obj = Object.entries(protections).reduce((acc, [key, value]) => {
        const category = value.category as TProtectionCategories;
        if (acc[category]) {
            acc[category].add(key as typeof AllProtectionKeys[number]);
        } else {
            acc[category] = new Set([key] as Array<typeof AllProtectionKeys[number]>);
        }
        return acc;
    }, {} as Record<TProtectionCategories, Set<typeof AllProtectionKeys[number]>>)

    return obj;
}