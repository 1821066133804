/** @jsxImportSource @emotion/react */
import React from 'react';
import { Skeleton } from "@mui/material";
import { css } from '@emotion/react';
import clsx from 'clsx';
import Text from '../Text/Text';
import { PSApplicationCardStyle } from './PSApplicationCard.css';
import Icon from '../Icon/Icon';

type IProps = {
    appName?: string;
    imageUrl?: string;
    type?: 'all' | 'modified';
    isAiComponent?: boolean;
    isActive?: boolean;
    isLoading?: boolean;
    isDisabled?: boolean;
    isSelected?: boolean;
    isCustom?: boolean;
    onClick?: () => void;
}


const PSApplicationCard: React.FC<IProps> = (props) => {
    const { isLoading, isActive, isDisabled, appName, imageUrl, type, isAiComponent, isSelected, isCustom, onClick } = props;

    if (isLoading) return (
        <Skeleton variant="rectangular" sx={{ borderRadius: '12px', minHeight: '75px' }} />
    )

    return (
        <button onClick={onClick} disabled={isDisabled} css={PSApplicationCardStyle.self} className={clsx({ 'active': isActive, 'disabled': isDisabled, 'isSelected': isSelected })}>
            {!isCustom && <img src={imageUrl || 'https://via.placeholder.com/50'} width={50} height={50} alt="App Icon" onError={(e) => { e.currentTarget.src = 'https://via.placeholder.com/50' }} />}
            {isCustom && <Icon iconName='PSCustomApplicationIcon' iconSize={50} />}
            <Text ellipsis variant='bold'>{appName}</Text>
            {isAiComponent && <Text variant='small'>Ai Component</Text>}
            {type && <Text textCss={css`margin-left: auto;`} color='black-70' className='application-type'>{type === 'all' ? 'Default' : 'Modified'}</Text>}
            {isSelected && <Text variant='bold' textCss={css`margin-left: auto;`} color='blue' className='application-type'>Selected</Text>}
        </button>
    )
}

export default PSApplicationCard;