/** @jsxImportSource @emotion/react */
import React, { useMemo, useRef, useState } from 'react';
import { DateRange, DateRangePicker, LocalizationProvider, SingleInputDateRangeField } from '@mui/x-date-pickers-pro';
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon';
import { css } from '@emotion/react';
import { TextField, InputAdornment } from '@mui/material';
import { DateTime } from 'luxon';
import { SxTextStyle } from '../Text/Text.css';
import { PSMuiDateRangePickerStyle } from './PSDateRangePicker.css';
import { predefinedDateSelections, predefinedTimeSelections, TPredefinedDateOrTimeSelection, useDateContext } from '../../contexts';
import { datesToPredefined, formatDate, predefinedLabelMapper, predefinedToDates } from './PredefinedDates';
import Chip from '../Chip/Chip';
import Icon from '../Icon/Icon';
import Text from '../Text/Text';
import PSButton from '../PSButton/PSButton';
import PSTabs from '../PSTabs/PSTabs';
import PSTab from '../PSTab/PSTab';

type IProps = {};


const datesToDateTime = (dates: [Date, Date]): DateRange<DateTime<boolean>> => {
    return [DateTime.fromJSDate(dates[0]), DateTime.fromJSDate(dates[1])];
}

const PSMuiDateRangePicker: React.FC<IProps> = (props) => {
    const { } = props;

    const { date, setDate } = useDateContext();
    const [isDateValid, setIsDateValid] = useState(true);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const [dateRangeValue, setDateRangeValue] = useState<DateRange<DateTime<boolean>> | undefined>(() => datesToDateTime(date.dates));
    const [dateTab, setDateTab] = useState<'hour' | 'date'>('date');
    const textFieldRef = useRef<HTMLDivElement>(null);

    const currentTimeZone = DateTime.local().zoneName;

    const dateRangeValueFormatted = useMemo(() => {
        if (date.type === 'relative') return predefinedLabelMapper[date.period];

        const datesToFormat = datesToDateTime(date.dates);

        const dateRangeValueToDates = datesToFormat.map(date => date?.toJSDate() || new Date()) as [Date, Date];
        const period = datesToPredefined(dateRangeValueToDates[0], dateRangeValueToDates[1]);

        if (period !== 'Custom') {
            return predefinedLabelMapper[period];
        }

        const from = dateRangeValueToDates[0] ? formatDate(dateRangeValueToDates[0]) : 'DD/MM/YYYY hh:mm';
        const to = dateRangeValueToDates[1] ? formatDate(dateRangeValueToDates[1]) : 'DD/MM/YYYY hh:mm';
        return `${from} - ${to}`;

    }, [date]);

    const textFieldWidthBasedOnValue = useMemo(() => {
        if (dateRangeValueFormatted) {
            const width = (dateRangeValueFormatted.length * 10) + 20;
            return width <= 220 ? 220 : width;
        }
        return 200;
    }, [dateRangeValueFormatted]);

    const onDateRangeAccept = (newValue: DateRange<DateTime<boolean>> | undefined) => {
        if (!newValue) return setIsDateValid(false);

        if (newValue[0] === null) return setIsDateValid(false);

        if (newValue[1] === null) return setIsDateValid(false);

        const from = newValue[0].startOf('day');
        const to = newValue[1].endOf('day');

        setDate({
            type: 'absolute',
            period: 'custom',
            dates: [from.toJSDate(), to.toJSDate()]
        });
    }

    const onDateRangeChange = (newValue: DateRange<DateTime<boolean>> | undefined) => {
        if (!newValue) return setIsDateValid(false);

        if (newValue[0] === null) return setIsDateValid(false);

        if (newValue[1] === null) return setIsDateValid(false);

        const from = newValue[0].startOf('day');
        const to = newValue[1].endOf('day');

        setDateRangeValue([from, to]);
        setIsDateValid(true);
    }

    const onDateApply = () => {
        onDateRangeAccept(dateRangeValue);
        setIsDatePickerOpen(false);
    }

    const onDateCancel = () => {
        setIsDatePickerOpen(false);
        setDateRangeValue(datesToDateTime(date.dates));
    }

    const predefinedDateClicked = (label: TPredefinedDateOrTimeSelection) => {
        const dates = predefinedToDates(label);

        if (dates) {
            setDateRangeValue(datesToDateTime(dates));
            setDate({
                type: 'relative',
                period: label,
                dates: dates
            })
            setIsDatePickerOpen(false);
        }
    }


    return (
        <LocalizationProvider dateAdapter={AdapterLuxon}>
            <TextField
                ref={textFieldRef}
                css={PSMuiDateRangePickerStyle.self}
                style={{ width: textFieldWidthBasedOnValue }}
                size="small"
                placeholder="Select Date Range"
                value={dateRangeValueFormatted}
                onClick={() => setIsDatePickerOpen(true)}
                InputProps={{
                    readOnly: true,
                    startAdornment: (
                        <InputAdornment position="start">
                            <Icon color={isDatePickerOpen ? 'black' : 'black-70'} iconName="CalendarTodayRounded" />
                            <Text textCss={css`margin-left: 8px;`}>Date:</Text>
                        </InputAdornment>
                    ),
                }}
                inputProps={{
                    "aria-readonly": true
                }}
            />
            <DateRangePicker
                className='date-range-picker'
                open={isDatePickerOpen}
                onClose={() => setIsDatePickerOpen(false)}
                onOpen={() => setIsDatePickerOpen(true)}
                value={dateRangeValue}
                label={dateRangeValueFormatted}
                format='dd/MM/yyyy, hh:m a'
                disableFuture
                closeOnSelect={false}
                timezone={currentTimeZone}
                onChange={onDateRangeChange}
                dayOfWeekFormatter={(date: DateTime) => {
                    return date.toFormat('ccc').slice(0, 2);
                }}
                disableAutoMonthSwitching
                slots={{
                    field: SingleInputDateRangeField,
                    toolbar: () => <div css={css`grid-column: 2;`}>
                        <PSTabs value={dateTab} onChange={(_, newValue) => setDateTab(newValue)}>
                            <PSTab value="date" label="Date" />
                            <PSTab value="hour" label="Hour" />
                        </PSTabs>
                        <div css={css`
                                padding: 16px;
                                border-bottom: 1px solid rgba(0, 0, 0, 0.12);
                            `}>
                            <div
                                css={css`
                                    display: flex;
                                    flex-direction: row;
                                    align-items: start;
                                    justify-content: center;
                                    gap: 10px;
                                    max-width: 600px;
                                    flex-wrap: wrap;
                                `}
                            >
                                {(dateTab === 'date' ? predefinedDateSelections : predefinedTimeSelections).map((period) => {
                                    return <Chip
                                        key={period}
                                        variant='outlined'
                                        onClick={() => {
                                            predefinedDateClicked(period);
                                        }}
                                        label={predefinedLabelMapper[period]}
                                    />
                                })
                                }
                            </div>
                        </div>
                    </div>,
                    shortcuts: () =>
                        <div css={css`
                        grid-column: 2;
                        grid-row: 3;
                        padding: 16px;
                        display: flex;
                        gap: 10px;
                        border-top: 1px solid rgba(0, 0, 0, 0.12);
                        align-items: center;
                    `}>
                            <Text textCss={css`font-size: 14px;`}>{
                                dateRangeValue && dateRangeValue[0] && dateRangeValue[1] && formatDate(dateRangeValue[0].toJSDate()) + ' - ' + formatDate(dateRangeValue[1].toJSDate())
                            }</Text>
                            <div css={css`display: flex; justify-content: end; align-items: center; margin-left: auto; gap: 10px;`}>
                                <PSButton variant="flat" onClick={onDateCancel}>Cancel</PSButton>
                                <PSButton variant="filled" onClick={onDateApply}>Apply</PSButton>
                            </div>
                        </div>,

                }}
                slotProps={{
                    toolbar: {
                        hidden: true,
                        toolbarFormat: 'MMM d yyyy',
                    },
                    popper: {
                        anchorEl: textFieldRef.current,
                        sx: {
                            '.MuiPickersLayout-contentWrapper': {
                                display: dateTab === 'hour' ? 'none' : 'flex',
                            },
                            '.MuiTypography-subtitle1': {
                                ...SxTextStyle.text,
                                color: 'var(--color-black)',
                            },
                            '.MuiDayCalendar-weekDayLabel': {
                                ...SxTextStyle.text,
                                color: 'var(--color-black-70)',

                            }
                        }
                    },
                    desktopPaper: {
                        sx: {
                            borderRadius: '10px',
                            boxShadow: '0px 5px 10px 0px rgba(0, 0, 0, 0.15)',
                        }
                    },
                    textField: {
                        size: 'small',
                        error: !isDateValid,
                        label: dateRangeValueFormatted,

                        sx: {
                            display: 'none',
                            width: 390,
                            '.MuiInputBase-root': {
                                ...SxTextStyle.bold,
                                color: 'var(--color-black)',
                            }
                        }
                    },
                    day: {
                        sx: {
                            '&.MuiDateRangePickerDay-rangeIntervalDayHighlight': {
                                '.MuiPickersDay-root': {
                                    color: 'var(--color-blue)',
                                }
                            },
                            '.MuiDateRangePickerDay-rangeIntervalPreview': {
                                '.MuiPickersDay-root': {
                                }
                            },
                            '.MuiPickersDay-root': {
                                ...SxTextStyle.text,
                                width: 37,
                                height: 37,
                                color: 'var(--color-black-70)',
                                '&.Mui-selected': {
                                    color: 'var(--color-white)',
                                    background: 'var(--color-blue)',
                                    ...SxTextStyle.bold
                                },
                            },

                        }
                    },

                }}
                name="allowedRange"
            />
        </LocalizationProvider>
    )
}

export default PSMuiDateRangePicker;