import { css } from "@emotion/react";

export const NavigationMenuStyle = {
  self: css`
    position: relative;
    display: flex;
    flex-direction: column;
    background: var(--color-background);
    padding: 30px 20px;
    overflow: hidden;

    &.collapsed {
      padding: 30px 10px;

      & .navigation-actions-container {
        align-items: center;

        .collapse-button .icon {
          transform: rotate(180deg);
        }
      }
    }
  `,

  promptLogoContainer: css`
    display: flex;
  `,

  navigationActionsContainer: css`
    margin-top: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
  `,

  navigation: css`
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 20px;
  `,

  collapseButton: css`
    &:not(.collapsed) {
      align-self: start;
    }
  `,

  userMenuContainer: css`
    padding: 15px;
  `,
};