/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { ConfigurationItemTextRedaction } from './ConfigurationTypes/ConfigurationItemTextRedaction';
import { ConfigurationItemExtension } from './ConfigurationTypes/ConfigurationItemExtension';
import { request } from "graphql-request";
import { useQuery } from "@tanstack/react-query";
import { graphql, graphqlEndpoint } from "../../gql";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { UpdateExtensionConfigurationInput } from '../../gql/generated/graphql';
import { ConfigurationItemProtectApiQuota } from './ConfigurationTypes/ConfigurationItemProtectApiQuota';
import { useUser } from '@descope/react-sdk';
import { ConfigurationItemLoggingFrequency } from './ConfigurationTypes/ConfigurationItemLoggingFrequency';
import { useLocalStorage } from 'react-use';
import { PSButton, PSDialog, Text } from '../../ui-kit';
import { BorderBottom } from '@mui/icons-material';

const queryConfiguration = graphql(`
    query Configuration {
        configuration {
            id
            textRedactionMode
            protectApiQuota {
              remaining
            }
            loggingFrequencyInSeconds
        }
    }
`);

const mutationUpdateConfiguration = graphql(`
    mutation mutationUpdateConfiguration($id: ID!, $textRedactionMode: String!, $remainingProtectApiQuota: Int!, $loggingFrequencyInSeconds: Int!) {
        updateConfiguration(input: { id: $id, textRedactionMode: $textRedactionMode, remainingProtectApiQuota: $remainingProtectApiQuota, loggingFrequencyInSeconds: $loggingFrequencyInSeconds }) {
            id
        }
    }
`);

const mutationUpdateCoachAwareness = graphql(`
  mutation mutationSetGenAiApplicationsGlobalSettingsCoachAwareness($coachAwareness: CoachAwareness!) {
      setGenAiApplicationsGlobalSettingsCoachAwareness(coachAwareness: $coachAwareness)
  }
`);

const getCoachAwareness = graphql(`
  query GetCoachAwareness {
      getGenAiApplicationsGlobalSettings {
          coachAwareness
      }
  }
`)

const queryExtensionConfiguration = graphql(`
    query ExtensionConfiguration {
        extensionConfiguration {
            id
            blockChatGPTTraining
            blockChatGPTMemory
            blockChatGPTFiles
            inspectFiles
            enforceChatGPTTempChat
            workProfileDomain
            sendPromptsMode
            enforceChatGPTWorkspace
            isExtensionDisabled
            failClose
            customMessageBlockPage
            customMessageCoachAwareness
            customMessageDomainUsageWarning
            customImage
            customGuidelinesLink
        }
    }
`);

const mutationUpdateExtensionConfiguration = graphql(`
    mutation mutationUpdateExtensionConfiguration($id: ID!, $blockChatGPTTraining: Boolean, $blockChatGPTMemory: Boolean, $blockChatGPTFiles: String, $inspectFiles: Boolean, $enforceChatGPTTempChat: Boolean, $workProfileDomain: String, $sendPromptsMode: String, $enforceChatGPTWorkspace: String, $isExtensionDisabled: Boolean, $failClose: Boolean, $customMessageBlockPage: String, $customMessageCoachAwareness: String, $customMessageDomainUsageWarning: String, $customImage: String, $customGuidelinesLink: String) {
      updateExtensionConfiguration(
        input: { 
          id: $id,
          blockChatGPTTraining: $blockChatGPTTraining,
          blockChatGPTMemory: $blockChatGPTMemory,
          blockChatGPTFiles: $blockChatGPTFiles,
          inspectFiles: $inspectFiles,
          enforceChatGPTTempChat: $enforceChatGPTTempChat,
          workProfileDomain: $workProfileDomain,
          sendPromptsMode: $sendPromptsMode,
          enforceChatGPTWorkspace: $enforceChatGPTWorkspace,
          isExtensionDisabled: $isExtensionDisabled,
          failClose: $failClose,
          customMessageBlockPage: $customMessageBlockPage,
          customMessageCoachAwareness: $customMessageCoachAwareness,
          customMessageDomainUsageWarning: $customMessageDomainUsageWarning,
          customImage: $customImage,
          customGuidelinesLink: $customGuidelinesLink
        }
      ) {
            id
        }
    }
`);

export const ConfigurationViewer = () => {


  const [showExtension, setShowExtension] = useLocalStorage('showExtension', false);

  useEffect(() => {
    if (window.location.hash === '#showExtension=true') {
      setShowExtension(true);
      window.location.hash = '';
    } else if (window.location.hash === '#showExtension=false') {
      setShowExtension(false);
      window.location.hash = '';
    }
  }, [])

  const { data: configuration } = useQuery(
    {
      queryKey: ["configuration"],
      queryFn: async () => {
        const { configuration } = await request(graphqlEndpoint, queryConfiguration);
        return configuration
      },
    }
  )

  const { data: extensionConfiguration } = useQuery(
    {
      queryKey: ["extensionConfiguration"],
      queryFn: async () => {
        const { extensionConfiguration } = await request(graphqlEndpoint, queryExtensionConfiguration);
        return extensionConfiguration
      },
    }
  )

  const { data: coachAwareness } = useQuery(
    {
      queryKey: ["coachAwareness"],
      queryFn: async () => {
        const { getGenAiApplicationsGlobalSettings } = await request(graphqlEndpoint, getCoachAwareness);
        return getGenAiApplicationsGlobalSettings?.coachAwareness;
      },
    }
  )

  useEffect(() => {
    if (configuration !== undefined) {
      setRedactionModeToDisplay(configuration.textRedactionMode);
      setRemainingProtectApiQuotaToDisplay(configuration.protectApiQuota.remaining);
      setLoggingFrequencyInSecondsToDisplay(configuration.loggingFrequencyInSeconds);
    }
  }, [configuration]);

  useEffect(() => {
    if (extensionConfiguration !== undefined) {
      setBlockChatGPTTrainingToDisplay(extensionConfiguration.blockChatGPTTraining);
      setBlockChatGPTMemoryToDisplay(extensionConfiguration.blockChatGPTMemory);
      setBlockChatGPTFilesToDisplay(extensionConfiguration.blockChatGPTFiles);
      setInspectFilesToDisplay(extensionConfiguration.inspectFiles);
      setEnforceChatGPTTempChatToDisplay(extensionConfiguration.enforceChatGPTTempChat);
      setWorkProfileDomainToDisplay(extensionConfiguration.workProfileDomain);
      setSendPromptsModeToDisplay(extensionConfiguration.sendPromptsMode);
      setEnforceChatGPTWorkspaceToDisplay(extensionConfiguration.enforceChatGPTWorkspace);
      setIsExtensionDisabledToDisplay(extensionConfiguration.isExtensionDisabled);
      setFailCloseToDisplay(extensionConfiguration.failClose);
      setCustomMessageBlockPageToDisplay(extensionConfiguration.customMessageBlockPage);
      setCustomMessageCoachAwarenessToDisplay(extensionConfiguration.customMessageCoachAwareness);
      setCustomMessageDomainUsageWarningToDisplay(extensionConfiguration.customMessageDomainUsageWarning);
      setCustomImageToDisplay(extensionConfiguration.customImage);
      setCustomGuidelinesLinkToDisplay(extensionConfiguration.customGuidelinesLink);
    }
  }, [extensionConfiguration]);

  useEffect(() => {
    if (coachAwareness !== undefined) {
      setCoachAwarenessToDisplay(coachAwareness);
    }
  }, [coachAwareness]);

  const { user } = useUser();
  const queryClient = useQueryClient();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [redactionConfigurationEditMode, setRedactionConfigurationEditMode] = useState<boolean>(false);
  const [coachAwarenessEditMode, setCoachAwarenessEditMode] = useState<boolean>(false);
  const [remainingProtectApiQuotaConfigurationEditMode, setRemainingProtectApiQuotaConfigurationEditMode] = useState<boolean>(false);
  const [loggingFrequencyInSecondsConfigurationEditMode, setLoggingFrequencyInSecondsConfigurationEditMode] = useState<boolean>(false);
  const [extensionConfigurationEditMode, setExtensionConfigurationEditMode] = useState<boolean>(false);
  const [openSavePopup, setOpenSavePopup] = useState<boolean>(false);
  const [redactionModeToDisplay, setRedactionModeToDisplay] = useState<string | undefined>();
  const [remainingProtectApiQuotaToDisplay, setRemainingProtectApiQuotaToDisplay] = useState<number | undefined>();
  const [loggingFrequencyInSecondsToDisplay, setLoggingFrequencyInSecondsToDisplay] = useState<number | undefined>();
  const [isExtensionDisabledToDisplay, setIsExtensionDisabledToDisplay] = useState<boolean | undefined>();
  const [failCloseToDisplay, setFailCloseToDisplay] = useState<boolean | undefined>();
  const [blockChatGPTTrainingToDisplay, setBlockChatGPTTrainingToDisplay] = useState<boolean | undefined>();
  const [blockChatGPTMemoryToDisplay, setBlockChatGPTMemoryToDisplay] = useState<boolean | undefined>();
  const [blockChatGPTFilesToDisplay, setBlockChatGPTFilesToDisplay] = useState<string | undefined>();
  const [inspectFilesToDisplay, setInspectFilesToDisplay] = useState<boolean | undefined>();
  const [enforceChatGPTTempChatToDisplay, setEnforceChatGPTTempChatToDisplay] = useState<boolean | undefined>();
  const [workProfileDomainToDisplay, setWorkProfileDomainToDisplay] = useState<string | undefined>();
  const [sendPromptsModeToDisplay, setSendPromptsModeToDisplay] = useState<string | undefined>();
  const [enforceChatGPTWorkspaceToDisplay, setEnforceChatGPTWorkspaceToDisplay] = useState<string | undefined>();
  const [customMessageBlockPageToDisplay, setCustomMessageBlockPageToDisplay] = useState<string | undefined>();
  const [customMessageCoachAwarenessToDisplay, setCustomMessageCoachAwarenessToDisplay] = useState<string | undefined>();
  const [coachAwarenessToDisplay, setCoachAwarenessToDisplay] = useState<string | undefined>();
  const [customMessageDomainUsageWarningToDisplay, setCustomMessageDomainUsageWarningToDisplay] = useState<string | undefined>();
  const [customImageToDisplay, setCustomImageToDisplay] = useState<string | undefined>();
  const [customGuidelinesLinkToDisplay, setCustomGuidelinesLinkToDisplay] = useState<string | undefined>();
  const [popupText, setPopupText] = useState<string>('');
  const [popupTitle, setPopupTitle] = useState<string>('');

  const updateConfiguration = useMutation({
    mutationFn: async (variables: any) => {
      await request(graphqlEndpoint, mutationUpdateConfiguration, variables);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["configuration"] });
      queryClient.invalidateQueries({ queryKey: ["alerts"] });
      setPopupText('Your configuration changes have been successfully saved');
      setPopupTitle('Configuration Updated');
      setOpenSavePopup(true);
    },
    onError: (error) => {
      const errorMessage = 'Failed to update configuration';
      setPopupText(errorMessage);
      setPopupTitle('Configuration Update Failed');
      setOpenSavePopup(true);
    }
  });

  const updateCoachAwareness = useMutation({
    mutationFn: async (variables: any) => {
      await request(graphqlEndpoint, mutationUpdateCoachAwareness, variables);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["coachAwareness"] });
      setPopupText('Your configuration changes have been successfully saved');
      setPopupTitle('Configuration Updated');
      setOpenSavePopup(true);
    },
    onError: (error) => {
      const errorMessage = 'Failed to update configuration';
      setPopupText(errorMessage);
      setPopupTitle('Configuration Update Failed');
      setOpenSavePopup(true);
    }
  });

  const updateExtensionConfiguration = useMutation({
    mutationFn: async (variables: any) => {
      await request(graphqlEndpoint, mutationUpdateExtensionConfiguration, variables);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["extensionConfiguration"] });
      queryClient.invalidateQueries({ queryKey: ["alerts"] });
      setPopupText('Your configuration changes have been successfully saved');
      setPopupTitle('Configuration Updated');
      setOpenSavePopup(true);
    },
    onError: (error) => {
      const errorMessage = 'Failed to update configuration';
      setPopupText(errorMessage);
      setPopupTitle('Configuration Update Failed');
      setOpenSavePopup(true);
    }
  });

  const handleSavePopupClose = () => {
    setOpenSavePopup(false);
  };

  const isValidDomain = (domains: string[]): boolean => {
    const domainReg = /^(?!:\/\/)([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,}$/;
    for (let domain of domains) {
      if (domain.match(domainReg) === null) {
        setPopupText(`The domain '${domain}' is not a valid domain`);
        setPopupTitle('Configuration Update Failed');
        setOpenSavePopup(true);
        return false;
      }
    }
    return true;
  }

  const handleSave = () => {
    if (workProfileDomainToDisplay) {
      if (isValidDomain([workProfileDomainToDisplay]) === false) {
        return;
      }
    }

    if (
      redactionModeToDisplay !== configuration?.textRedactionMode ||
      remainingProtectApiQuotaToDisplay !== configuration?.protectApiQuota.remaining ||
      loggingFrequencyInSecondsToDisplay !== configuration?.loggingFrequencyInSeconds
    ) {
      updateConfiguration.mutate({ id: configuration?.id, textRedactionMode: redactionModeToDisplay, remainingProtectApiQuota: remainingProtectApiQuotaToDisplay, loggingFrequencyInSeconds: loggingFrequencyInSecondsToDisplay });
    }

    if (coachAwarenessToDisplay !== coachAwareness) {
      updateCoachAwareness.mutate({ coachAwareness: coachAwarenessToDisplay });
    }

    let shouldSendExtension: boolean = false;
    if (extensionConfiguration) {
      let updateData: UpdateExtensionConfigurationInput = {
        id: extensionConfiguration.id,
      }

      if (isExtensionDisabledToDisplay !== extensionConfiguration.isExtensionDisabled) {
        updateData.isExtensionDisabled = isExtensionDisabledToDisplay;
        shouldSendExtension = true;
      }

      if (failCloseToDisplay !== extensionConfiguration.failClose) {
        updateData.failClose = failCloseToDisplay;
        shouldSendExtension = true;
      }

      if (blockChatGPTTrainingToDisplay !== extensionConfiguration.blockChatGPTTraining) {
        updateData.blockChatGPTTraining = blockChatGPTTrainingToDisplay;
        shouldSendExtension = true;
      }

      if (blockChatGPTMemoryToDisplay !== extensionConfiguration.blockChatGPTMemory) {
        updateData.blockChatGPTMemory = blockChatGPTMemoryToDisplay;
        shouldSendExtension = true;
      }

      if (blockChatGPTFilesToDisplay !== extensionConfiguration.blockChatGPTFiles) {
        updateData.blockChatGPTFiles = blockChatGPTFilesToDisplay;
        shouldSendExtension = true;
      }

      if (inspectFilesToDisplay !== extensionConfiguration.inspectFiles) {
        updateData.inspectFiles = inspectFilesToDisplay;
        shouldSendExtension = true;
      }

      if (enforceChatGPTTempChatToDisplay !== extensionConfiguration.enforceChatGPTTempChat) {
        updateData.enforceChatGPTTempChat = enforceChatGPTTempChatToDisplay;
        shouldSendExtension = true;
      }

      if (workProfileDomainToDisplay !== extensionConfiguration.workProfileDomain) {
        updateData.workProfileDomain = workProfileDomainToDisplay?.trim();
        shouldSendExtension = true;
      }

      if (sendPromptsModeToDisplay !== extensionConfiguration.sendPromptsMode) {
        updateData.sendPromptsMode = sendPromptsModeToDisplay;
        shouldSendExtension = true;
      }

      if (enforceChatGPTWorkspaceToDisplay !== extensionConfiguration.enforceChatGPTWorkspace) {
        updateData.enforceChatGPTWorkspace = enforceChatGPTWorkspaceToDisplay;
        shouldSendExtension = true;
      }

      if (customMessageBlockPageToDisplay !== extensionConfiguration.customMessageBlockPage) {
        updateData.customMessageBlockPage = customMessageBlockPageToDisplay?.trim();
        shouldSendExtension = true;
      }

      if (customMessageCoachAwarenessToDisplay !== extensionConfiguration.customMessageCoachAwareness) {
        updateData.customMessageCoachAwareness = customMessageCoachAwarenessToDisplay?.trim();
        shouldSendExtension = true;
      }

      if (customMessageDomainUsageWarningToDisplay !== extensionConfiguration.customMessageDomainUsageWarning) {
        updateData.customMessageDomainUsageWarning = customMessageDomainUsageWarningToDisplay?.trim();
        shouldSendExtension = true;
      }

      if (customImageToDisplay !== extensionConfiguration.customImage) {
        updateData.customImage = customImageToDisplay?.trim();
        shouldSendExtension = true;
      }

      if (customGuidelinesLinkToDisplay !== extensionConfiguration.customGuidelinesLink) {
        updateData.customGuidelinesLink = customGuidelinesLinkToDisplay?.trim();
        shouldSendExtension = true;
      }

      if (shouldSendExtension) {
        updateExtensionConfiguration.mutate(updateData);
      }
    }

    setEditMode(false);
    setExtensionConfigurationEditMode(false);
    setRedactionConfigurationEditMode(false);
    setRemainingProtectApiQuotaConfigurationEditMode(false);
    setLoggingFrequencyInSecondsConfigurationEditMode(false);
    setCoachAwarenessEditMode(false);
  };

  const handleCancel = () => {
    setRedactionModeToDisplay(configuration?.textRedactionMode);
    setRemainingProtectApiQuotaToDisplay(configuration?.protectApiQuota.remaining);
    setLoggingFrequencyInSecondsToDisplay(configuration?.loggingFrequencyInSeconds);
    setBlockChatGPTTrainingToDisplay(extensionConfiguration?.blockChatGPTTraining);
    setBlockChatGPTMemoryToDisplay(extensionConfiguration?.blockChatGPTMemory);
    setBlockChatGPTFilesToDisplay(extensionConfiguration?.blockChatGPTFiles);
    setInspectFilesToDisplay(extensionConfiguration?.inspectFiles);
    setEnforceChatGPTTempChatToDisplay(extensionConfiguration?.enforceChatGPTTempChat);
    setWorkProfileDomainToDisplay(extensionConfiguration?.workProfileDomain);
    setSendPromptsModeToDisplay(extensionConfiguration?.sendPromptsMode);
    setEnforceChatGPTWorkspaceToDisplay(extensionConfiguration?.enforceChatGPTWorkspace);
    setIsExtensionDisabledToDisplay(extensionConfiguration?.isExtensionDisabled);
    setFailCloseToDisplay(extensionConfiguration?.failClose);
    setCustomMessageBlockPageToDisplay(extensionConfiguration?.customMessageBlockPage);
    setCustomMessageCoachAwarenessToDisplay(extensionConfiguration?.customMessageCoachAwareness);
    setCustomMessageDomainUsageWarningToDisplay(extensionConfiguration?.customMessageDomainUsageWarning);
    setCustomImageToDisplay(extensionConfiguration?.customImage);
    setCustomGuidelinesLinkToDisplay(extensionConfiguration?.customGuidelinesLink);
    setCoachAwarenessToDisplay(coachAwareness);
    setEditMode(false);
    setExtensionConfigurationEditMode(false);
    setRedactionConfigurationEditMode(false);
    setRemainingProtectApiQuotaConfigurationEditMode(false);
    setLoggingFrequencyInSecondsConfigurationEditMode(false);
    setCoachAwarenessEditMode(false);
  };

  const handleRedactionModeConfigurationChange = (redactionMode: string) => {
    if (redactionMode !== redactionModeToDisplay) {
      setRedactionModeToDisplay(redactionMode);
    }

    if (redactionMode !== configuration?.textRedactionMode) {
      setRedactionConfigurationEditMode(true);
      setEditMode(true);
    } else {
      setRedactionConfigurationEditMode(false);
      if (!coachAwarenessEditMode && !extensionConfigurationEditMode && !remainingProtectApiQuotaConfigurationEditMode && !loggingFrequencyInSecondsConfigurationEditMode) {
        setEditMode(false);
      }
    }
  }

  const handleRemainingProtectApiQuotaConfigurationChange = (remainingProtectApiQuota: number) => {
    if (remainingProtectApiQuota !== remainingProtectApiQuotaToDisplay) {
      setRemainingProtectApiQuotaToDisplay(remainingProtectApiQuota);
    }

    if (remainingProtectApiQuota !== configuration?.protectApiQuota.remaining) {
      setRemainingProtectApiQuotaConfigurationEditMode(true);
      setEditMode(true);
    } else {
      setRemainingProtectApiQuotaConfigurationEditMode(false);
      if (!coachAwarenessEditMode && !extensionConfigurationEditMode && !redactionConfigurationEditMode && !loggingFrequencyInSecondsConfigurationEditMode) {
        setEditMode(false);
      }
    }
  }

  const handleLoggingFrequencyInSecondsConfigurationChange = (loggingFrequencyInSeconds: number) => {
    if (loggingFrequencyInSeconds !== loggingFrequencyInSecondsToDisplay) {
      setLoggingFrequencyInSecondsToDisplay(loggingFrequencyInSeconds);
    }

    if (loggingFrequencyInSeconds !== configuration?.loggingFrequencyInSeconds) {
      setLoggingFrequencyInSecondsConfigurationEditMode(true);
      setEditMode(true);
    } else {
      setLoggingFrequencyInSecondsConfigurationEditMode(false);
      if (!coachAwarenessEditMode && !extensionConfigurationEditMode && !redactionConfigurationEditMode && !remainingProtectApiQuotaConfigurationEditMode) {
        setEditMode(false);
      }
    }
  }

  const handleCoachAwarenessChange = (coachAwarenessChange: string) => {
    if (coachAwarenessChange !== coachAwarenessToDisplay) {
      setCoachAwarenessToDisplay(coachAwarenessChange);
    }

    if (coachAwarenessChange !== coachAwareness) {
      setCoachAwarenessEditMode(true);
      setEditMode(true);
    } else {
      setCoachAwarenessEditMode(false);
      if (!extensionConfigurationEditMode && !remainingProtectApiQuotaConfigurationEditMode && !loggingFrequencyInSecondsConfigurationEditMode) {
        setEditMode(false);
      }
    }
  }

  const handleExtensionConfigurationChange = (
    isExtensionDisabled: boolean,
    failClose: boolean,
    blockChatGPTTraining: boolean,
    blockChatGPTMemory: boolean,
    blockChatGPTFiles: string,
    inspectFiles: boolean,
    enforceChatGPTTempChat: boolean,
    workProfileDomain: string,
    sendPromptsMode: string,
    enforceChatGPTWorkspace: string,
    customMessageBlockPage: string,
    customMessageCoachAwareness: string,
    customMessageDomainUsageWarning: string,
    customImage: string,
    customGuidelinesLink: string
  ) => {
    if (isExtensionDisabled !== isExtensionDisabledToDisplay) {
      setIsExtensionDisabledToDisplay(isExtensionDisabled);
    }
    if (failClose !== failCloseToDisplay) {
      setFailCloseToDisplay(failClose);
    }
    if (blockChatGPTTraining !== blockChatGPTTrainingToDisplay) {
      setBlockChatGPTTrainingToDisplay(blockChatGPTTraining);
    }
    if (blockChatGPTMemory !== blockChatGPTMemoryToDisplay) {
      setBlockChatGPTMemoryToDisplay(blockChatGPTMemory);
    }
    if (blockChatGPTFiles !== blockChatGPTFilesToDisplay) {
      setBlockChatGPTFilesToDisplay(blockChatGPTFiles);
    }
    if (inspectFiles !== inspectFilesToDisplay) {
      setInspectFilesToDisplay(inspectFiles);
    }
    if (enforceChatGPTTempChat !== enforceChatGPTTempChatToDisplay) {
      setEnforceChatGPTTempChatToDisplay(enforceChatGPTTempChat);
    }
    if (workProfileDomain !== workProfileDomainToDisplay) {
      setWorkProfileDomainToDisplay(workProfileDomain);
    }
    if (sendPromptsMode !== sendPromptsModeToDisplay) {
      setSendPromptsModeToDisplay(sendPromptsMode);
    }
    if (enforceChatGPTWorkspace !== enforceChatGPTWorkspaceToDisplay) {
      setEnforceChatGPTWorkspaceToDisplay(enforceChatGPTWorkspace);
    }
    if (customMessageBlockPage !== customMessageBlockPageToDisplay) {
      setCustomMessageBlockPageToDisplay(customMessageBlockPage);
    }
    if (customMessageCoachAwareness !== customMessageCoachAwarenessToDisplay) {
      setCustomMessageCoachAwarenessToDisplay(customMessageCoachAwareness);
    }
    if (customMessageDomainUsageWarning !== customMessageDomainUsageWarningToDisplay) {
      setCustomMessageDomainUsageWarningToDisplay(customMessageDomainUsageWarning);
    }
    if (customImage !== customImageToDisplay) {
      setCustomImageToDisplay(customImage);
    }
    if (customGuidelinesLink !== customGuidelinesLinkToDisplay) {
      setCustomGuidelinesLinkToDisplay(customGuidelinesLink);
    }
    if (
      isExtensionDisabled !== extensionConfiguration?.isExtensionDisabled ||
      failClose !== extensionConfiguration?.failClose ||
      blockChatGPTTraining !== extensionConfiguration?.blockChatGPTTraining ||
      blockChatGPTMemory !== extensionConfiguration?.blockChatGPTMemory ||
      blockChatGPTFiles !== extensionConfiguration?.blockChatGPTFiles ||
      inspectFiles !== extensionConfiguration?.inspectFiles ||
      enforceChatGPTTempChat !== extensionConfiguration?.enforceChatGPTTempChat ||
      workProfileDomain !== extensionConfiguration?.workProfileDomain ||
      sendPromptsMode !== extensionConfiguration?.sendPromptsMode ||
      enforceChatGPTWorkspace !== extensionConfiguration?.enforceChatGPTWorkspace ||
      customMessageBlockPage !== extensionConfiguration?.customMessageBlockPage ||
      customMessageCoachAwareness !== extensionConfiguration?.customMessageCoachAwareness ||
      customMessageDomainUsageWarning !== extensionConfiguration?.customMessageDomainUsageWarning ||
      customImage !== extensionConfiguration?.customImage ||
      customGuidelinesLink !== extensionConfiguration?.customGuidelinesLink
    ) {
      setExtensionConfigurationEditMode(true);
      setEditMode(true);
    } else {
      setExtensionConfigurationEditMode(false);
      if (!coachAwarenessEditMode && !redactionConfigurationEditMode && !remainingProtectApiQuotaConfigurationEditMode && !loggingFrequencyInSecondsConfigurationEditMode) {
        setEditMode(false);
      }
    }
  }

  const displayRedactionConfiguration = redactionModeToDisplay !== undefined;
  const displayRemainingProtectApiQuotaConfiguration = remainingProtectApiQuotaToDisplay !== undefined;
  const displayLoggingFrequencyInSecondsConfiguration = loggingFrequencyInSecondsToDisplay !== undefined;
  const displayCoachAwareness = coachAwarenessToDisplay !== undefined;
  const displayExtension = (
    isExtensionDisabledToDisplay !== undefined &&
    failCloseToDisplay !== undefined &&
    blockChatGPTTrainingToDisplay !== undefined &&
    blockChatGPTMemoryToDisplay !== undefined &&
    blockChatGPTFilesToDisplay !== undefined &&
    inspectFilesToDisplay !== undefined &&
    enforceChatGPTTempChatToDisplay !== undefined &&
    workProfileDomainToDisplay !== undefined &&
    sendPromptsModeToDisplay !== undefined &&
    enforceChatGPTWorkspaceToDisplay !== undefined &&
    customMessageBlockPageToDisplay !== undefined &&
    customMessageCoachAwarenessToDisplay !== undefined &&
    customMessageDomainUsageWarningToDisplay !== undefined &&
    customImageToDisplay !== undefined &&
    customGuidelinesLinkToDisplay !== undefined
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'column', overflow: 'auto', flex: 1 }}>
      <div style={{ paddingBottom: '20px', borderBottom: '1px solid var(--color-black-25)' }}>
        <Text variant='header2'>Configuration </Text>
      </div>
      <div style={{ flex: 1, overflow: 'auto' }}>
        {displayRedactionConfiguration && <ConfigurationItemTextRedaction inputRedactionMode={redactionModeToDisplay} editMode={editMode} handleConfigurationChange={handleRedactionModeConfigurationChange} />}
        {displayLoggingFrequencyInSecondsConfiguration && <ConfigurationItemLoggingFrequency inputLoggingFrequencyInSeconds={loggingFrequencyInSecondsToDisplay} editMode={editMode} handleConfigurationChange={handleLoggingFrequencyInSecondsConfigurationChange} />}
        {displayRemainingProtectApiQuotaConfiguration && user?.email?.includes('@prompt.security') && <ConfigurationItemProtectApiQuota inputRemainingQuota={remainingProtectApiQuotaToDisplay} editMode={editMode} handleConfigurationChange={handleRemainingProtectApiQuotaConfigurationChange} />}
        {showExtension && displayExtension && displayCoachAwareness && <ConfigurationItemExtension inputIsExtensionDisabled={isExtensionDisabledToDisplay} inputFailClose={failCloseToDisplay} inputBlockChatGPTTraining={blockChatGPTTrainingToDisplay} inputBlockChatGPTMemory={blockChatGPTMemoryToDisplay} inputBlockChatGPTFiles={blockChatGPTFilesToDisplay} inputInspectFiles={inspectFilesToDisplay} inputEnforceChatGPTTempChat={enforceChatGPTTempChatToDisplay} inputWorkProfileDomain={workProfileDomainToDisplay} inputSendPromptsMode={sendPromptsModeToDisplay} inputEnforceChatGPTWorkspace={enforceChatGPTWorkspaceToDisplay} inputCustomMessageBlockPage={customMessageBlockPageToDisplay} inputCustomMessageCoachAwareness={customMessageCoachAwarenessToDisplay} inputCustomMessageDomainUsageWarning={customMessageDomainUsageWarningToDisplay} inputCustomImage={customImageToDisplay} inputCustomGuidelinesLink={customGuidelinesLinkToDisplay} editMode={editMode} handleConfigurationChange={handleExtensionConfigurationChange} inputCoachAwarenessState={coachAwarenessToDisplay} handleCoachAwarenessChange={handleCoachAwarenessChange} />}
      </div>
      <div style={{ display: 'flex', alignItems: 'center', borderTop: '1px solid var(--color-black-25)', paddingTop: '20px' }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: 10, background: 'var(--color-background)', marginLeft: 'auto' }}>
          <PSButton variant='flat' disabled={!editMode} onClick={handleCancel}>Cancel</PSButton>
          <PSButton variant='filled' disabled={!editMode} onClick={handleSave}>Save</PSButton>
        </div>
      </div>
      <PSDialog open={openSavePopup} onClose={handleSavePopupClose} title={popupTitle} isShowCancelButton={false}>
        <Text>{popupText}</Text>
      </PSDialog>
    </div>
  );
};