import { FormLabel, Tooltip } from "@mui/material";
import { Unstable_NumberInput as BaseNumberInput } from '@mui/base/Unstable_NumberInput';
import React from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { styled } from '@mui/system';

const StyledInputRoot = styled('div')(({ theme }) => ({
    fontFamily: 'IBM Plex Sans, sans-serif',
    fontWeight: 400,
    color: '#9DA8B7',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    alignItems: 'center',
}));

const StyledInput = styled('input')(({ theme }) => ({
    fontSize: '0.8rem',
    fontFamily: 'inherit',
    fontWeight: 400,
    color: '#1C2025',
    background: '#fff',
    border: '1px solid #DAE2ED',
    boxShadow: '0px 2px 4px rgba(0,0,0, 0.05)',
    borderRadius: '8px',
    margin: '1px 5px',
    padding: '5px 0px',
    outline: 0,
    minWidth: 0,
    width: '4rem',
    textAlign: 'center',

    '&:hover': {
        borderColor: '#3399ff',
    },
    
    '&:focus': {
    borderColor: '#3399ff',
    boxShadow: '0 0 0 3px #b6daff',
    },

    '&:focus-visible': {
        outline: 0,
    }
}));

const StyledButton = styled('button')(({ theme }) => ({
    fontFamily: 'IBM Plex Sans, sans-serif',
    lineHeight: 1,
    border: '0px solid',
    color: '#1C2025',
    width: '22px',
    height: '22px',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    transitionProperty: 'all',

    '&:hover': {
        cursor: 'pointer',
        background: '#E4E8EC',
        borderColor: '#E4E8EC',
        borderRadius: '15px'
    },

    '&.increment': {
      order: 1,
    }
}));

type IntInputProps = {
    value: number;
    title: string;
    help: string;
    disabled: boolean;
    minValue?: number;
    maxValue?: number;
    handleOnChange: (value: number | null) => void;
}
  
export const IntInput = ({ value, title, help, disabled, minValue, maxValue, handleOnChange }: IntInputProps) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '250px' }}>
            <Tooltip style={{ alignItems: 'center' }} key={title} title={help} placement='top-start'>
                <FormLabel component="legend"  sx={{ paddingTop: 1 }}>{<span style={{ color: 'black' }}>{title}</span>}</FormLabel>
            </Tooltip>
            <BaseNumberInput
                value={value}
                disabled={disabled}
                min={minValue}
                max={maxValue}
                onChange={(event, val) => handleOnChange(val)}
                slots={{
                    root: StyledInputRoot,
                    input: StyledInput,
                    incrementButton: StyledButton,
                    decrementButton: StyledButton,
                    }}
                    slotProps={{
                    incrementButton: {
                        children: <ArrowDropUpIcon />,
                        className: 'increment',
                    },
                    decrementButton: {
                        children: <ArrowDropDownIcon />,
                    },
                    }}
                />
        </div>
    )
}