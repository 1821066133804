/** @jsxImportSource @emotion/react */
import React, { useCallback } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import { SanitizeFileRequest } from './sanitizeFile';
import { PlaygroundFileFormStyle } from './PlaygroundFileForm.css';
import { Icon, PSButton, Text } from '../../../ui-kit';
import { fileSizeFormatter } from '../../../utils/number';

type IProps = {
    formModel: UseFormReturn<Omit<SanitizeFileRequest, "appId">, any, undefined>;
    isLoading?: boolean;
};

const isPdf = (file: File): boolean => {
    return file.type === 'application/pdf';
};

const isUnder100MB = (file: File): boolean => {
    return file.size <= 100 * 1024 * 1024; // 100MB
};

const PlaygroundFileForm: React.FC<IProps> = (props) => {
    const { formModel, isLoading } = props;
    const { watch, setValue } = formModel;

    const file = watch("file");

    const onDrop = useCallback((acceptedFiles: File[]) => {
        if (isLoading) return;

        const file = acceptedFiles[0];
        if (!isPdf) return;
        if (!isUnder100MB) return;
        if (!file) return;

        setValue("file", file);
    }, [setValue]);

    const { getRootProps, getInputProps, isDragAccept, isDragReject, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'application/pdf': ['.pdf']
        },
        maxFiles: 1,
        maxSize: 100 * 1024 * 1024,
    });

    return (
        <React.Fragment>
            <div {...getRootProps()} css={[
                PlaygroundFileFormStyle.dropFileContainer,
                isDragAccept && PlaygroundFileFormStyle.draggingOverStyle,
                isDragReject && PlaygroundFileFormStyle.fileNotAllowedStyle
            ]}>
                <input {...getInputProps()} disabled={isLoading} />
                {!isDragActive &&
                    <div css={PlaygroundFileFormStyle.dropTextContainer}>
                        <Icon iconName='UploadFileOutlined' color='black-50' iconSize={40} />
                        <div css={PlaygroundFileFormStyle.dropTextExplanation}>
                            <Text color='black-70'>Drop a PDF file here, Or click to upload a file</Text>
                            <Text color='black-70' variant='small'>100MB limit</Text>
                        </div>
                    </div>
                }
                {isDragAccept && <Text color='black-70'>File accepted, drop it!</Text>}
                {isDragReject && <Text color='black-70'>File type not accepted, please upload a PDF file</Text>}
            </div>
            {file &&
                <div css={PlaygroundFileFormStyle.fileDetailsContainer}>
                    <Icon iconName='InsertDriveFileOutlined' color='black-50' iconSize={40} />
                    <div css={PlaygroundFileFormStyle.fileDetails}>
                        <Text color='black-70'>{file.name}</Text>
                        <Text color='black-70' variant='small'>{fileSizeFormatter.format(file.size)}</Text>
                    </div>
                    {!isLoading && <PSButton
                        variant='flat'
                        onClick={() => setValue("file", null)}
                        css={PlaygroundFileFormStyle.deleteFileButton}
                        iconName='ClearRounded'
                    >
                        Remove File
                    </PSButton>}
                </div>
            }
        </React.Fragment>
    );
}

export default PlaygroundFileForm;