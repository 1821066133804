/** @jsxImportSource @emotion/react */
import { useUser } from '@descope/react-sdk';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { PSButton } from '../../../ui-kit';
import { TConnectorsNames, TConnectorsTypes, TProtectionTextType } from '../Protections';
import { PlaygroundTextStyle } from './PlaygroundText.css';
import PlaygroundTextForm from './PlaygroundTextForm';
import { protect, ProtectPromptRequest, ProtectResponseRequest } from './protect';
import ScanResults from './ScanResults';

type IProps = {
    appId: string;
    policy: TConnectorsTypes;
    isAdvancedMode: boolean;
    protectionTextType: TProtectionTextType;
    connectorType: TConnectorsNames;
};

const PlaygroundText: React.FC<IProps> = (props) => {
    const { appId, policy, isAdvancedMode, protectionTextType, connectorType } = props;

    const { user } = useUser();
    const protectForm = useForm<Omit<ProtectPromptRequest & ProtectResponseRequest, 'appId'>>();
    const scanResultRef = useRef<HTMLDivElement>(null);

    const performProtectTest = useMutation({
        mutationFn: async () => {
            const values = protectForm.getValues();
            const response = await protect({
                appId,
                policy,
                prompt: values.prompt,
                system_prompt: values.system_prompt,
                response: values.response,
                user: user.email
            });

            return response;
        },
    });

    useEffect(() => {
        if (performProtectTest.isSuccess && scanResultRef.current) {
            scanResultRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [performProtectTest.isSuccess]);


    return (
        <React.Fragment>
            <div css={PlaygroundTextStyle.formContainer}>
                <PlaygroundTextForm
                    control={protectForm.control}
                    protectionTextType={protectionTextType}
                    excludeSystemPrompt={connectorType !== 'homegrown'}
                    connectorType={connectorType}
                    performProtectTest={() => performProtectTest.mutateAsync()}
                    isFormValid={protectForm.formState.isValid}
                />

                <PSButton
                    variant='filled'
                    disabled={!protectForm.formState.isValid}
                    css={PlaygroundTextStyle.testButton}
                    isLoading={performProtectTest.isPending}
                    onClick={() => performProtectTest.mutateAsync()}
                >
                    Test
                </PSButton>
            </div>


            {
                !performProtectTest.isPending
                && performProtectTest.isSuccess
                && <ScanResults
                    ref={scanResultRef}
                    protectResponse={performProtectTest.data?.result}
                    isAdvancedMode={isAdvancedMode}
                    startRequestTime={performProtectTest.submittedAt}
                />
            }
        </React.Fragment>
    )
}

export default PlaygroundText;