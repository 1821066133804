/** @jsxImportSource @emotion/react */
import { Checkbox, CheckboxProps, Skeleton } from "@mui/material";
import { ColorThemeKeys } from "../../styles";
import { PSCheckboxCardStyle } from "./PSCheckboxCard.css";
import Text from "../Text/Text";
import PSCheckbox from "../PSCheckbox/PSCheckbox";
import PSIconButton from "../PSIconButton/PSIconButton";
import clsx from "clsx";
import PSImageBox from "../PSImageBox/PSImageBox";
import { getHostnameFromURL } from "../../utils";

type TCheckboxCardProps = {
    iconUrl?: string;
    title?: string;
    url?: string;
    isChecked?: boolean;
    isLoading?: boolean;
    isCustom?: boolean;
    selectedBorderColor?: ColorThemeKeys;
    onRemove?: () => void;
}

const PSCheckboxCardDefault: React.FC<TCheckboxCardProps> = (props) => {
    const { iconUrl, title, url, onRemove, isChecked, isLoading, isCustom, selectedBorderColor = 'blue' } = props;

    const handleLinkOpenClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault();
        event.stopPropagation();
        window.open(url, '_blank');
    }

    if (isLoading) return (
        <div css={PSCheckboxCardStyle.self} className={clsx({ 'loading': isLoading })}>
            <Skeleton variant="rectangular" width={70} height={70} sx={{ borderRadius: '10px' }} />
            <div css={PSCheckboxCardStyle.contentContainer}>
                <div css={PSCheckboxCardStyle.titleRow}>
                    <Skeleton variant="text" width={150} />
                    <Skeleton variant="rectangular" width={24} height={24} sx={{ borderRadius: '4px' }} />
                </div>
                <div css={PSCheckboxCardStyle.secondaryRow}>
                    <Skeleton variant="text" width={100} />
                </div>
            </div>
        </div>
    )

    return (
        <div css={[PSCheckboxCardStyle.self, isChecked && PSCheckboxCardStyle.selfChecked(selectedBorderColor)]}>
            {!isCustom && <PSImageBox imageUrl={iconUrl} />}
            {isCustom && <PSImageBox iconName="PSCustomApplicationIcon" />}
            <div css={PSCheckboxCardStyle.contentContainer}>
                <div css={PSCheckboxCardStyle.titleRow}>
                    <Text ellipsis variant="bold">{title}</Text>
                    <PSCheckbox fillColor={selectedBorderColor} tabIndex={-1} disableRipple disabled checked={!!isChecked} />
                </div>
                <div css={PSCheckboxCardStyle.secondaryRow}>
                    <Text ellipsis color="black-50">{getHostnameFromURL(url)}</Text>
                    <PSIconButton css={PSCheckboxCardStyle.iconButton} onClick={handleLinkOpenClick} iconName="OpenInNewRounded" iconSize={15} tooltipLabel="Open in new tab" />
                </div>
                {onRemove && <div css={PSCheckboxCardStyle.customRow}>
                    {isCustom && <Text textCss={PSCheckboxCardStyle.customAppLabel} color="black-50">Custom app</Text>}
                    <PSIconButton css={PSCheckboxCardStyle.iconButton} onClick={onRemove} iconName='DeleteRounded' iconSize={20} tooltipLabel="Delete application" />
                </div>}
            </div>
        </div>
    )
}


const PSCheckboxCard: React.FC<CheckboxProps & TCheckboxCardProps> = (props) => {
    const { iconUrl, title, url, onRemove, selectedBorderColor, isLoading, isCustom, ...checkboxProps } = props;

    const checkboxCardProps = {
        iconUrl,
        title,
        url,
        selectedBorderColor,
        isLoading,
        isCustom,
        onRemove
    }

    return (
        <Checkbox
            disableRipple
            color='default'
            css={PSCheckboxCardStyle.checkbox}
            icon={<PSCheckboxCardDefault {...checkboxCardProps} />}
            checkedIcon={<PSCheckboxCardDefault isChecked {...checkboxCardProps} />}
            disabled={isLoading || checkboxProps.disabled}
            {...checkboxProps}
        />
    )
}


export default PSCheckboxCard;