/** @jsxImportSource @emotion/react */
import React from 'react';
import { Link, useMatches } from 'react-router-dom';
import { NavigationTopBarStyle } from './NavigationTopBar.css';
import PSMuiDateRangePicker from '../ui-kit/PSDateRangePicker/PSMuiDateRangePicker';
import { Text } from '../ui-kit';
import ManageConnectorTopBarTopBar from './Manage/ManageConnectorTopBar/ManageConnectorTopBar';

type IProps = {};

const NavigationTopBar: React.FC<IProps> = () => {
    const matches = useMatches();

    const breadcrumbMatches = matches.filter(match => match.handle && (match.handle as any).breadcrumb);
    const shouldShowDateRange = breadcrumbMatches.length > 0 && (breadcrumbMatches[breadcrumbMatches.length - 1].handle as any).showDateRange;
    const shouldShowManageConnector = breadcrumbMatches.length > 0 && (breadcrumbMatches[breadcrumbMatches.length - 1].handle as any).shouldShowManageConnector;
    const manageConnectorTopBarProps = breadcrumbMatches.length > 0 && (breadcrumbMatches[breadcrumbMatches.length - 1].handle as any).manageConnectorTopBarProps;

    return (
        <nav css={NavigationTopBarStyle.self}>
            <ol className='page-bread-crumbs'>
                {breadcrumbMatches.map((match, index) => {
                    const isLast = index === breadcrumbMatches.length - 1;
                    return (
                        <li key={index}>
                            {isLast ? (
                                <Text inline variant='bold'>{(match.handle as any).breadcrumb}</Text>
                            ) : (
                                <Link to={match.pathname}>{(match.handle as any).breadcrumb}</Link>
                            )}
                        </li>
                    );
                })}
            </ol>
            {shouldShowManageConnector && <ManageConnectorTopBarTopBar
                connectorFirstUrlPart={manageConnectorTopBarProps.connectorFirstUrlPart}
                connectorType={manageConnectorTopBarProps.connectorType}
            />}
            {shouldShowDateRange && <PSMuiDateRangePicker />}

        </nav>
    );
}

export default NavigationTopBar;