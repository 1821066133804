/** @jsxImportSource @emotion/react */
import React from 'react';
import { Text } from '../../../../../ui-kit/'
import { css } from '@emotion/react';

type IProps = {

};

const FirefoxMac: React.FC<IProps> = (props) => {
    const { } = props;

    return (
        <ol css={css`list-style: number; margin-left: 30px; display: flex; flex-direction: column; gap: 10px;`}>
            <li><Text>In the downloaded txt file there are two files: <b>policies.json</b> and <b>extension@prompt.security.json</b></Text></li>
            <li><Text>The <b>policies.json</b> files should be pushed to the <b>/Applications/Firefox.app/Contents/Resources/distribution/</b> directory of your employees</Text></li>
            <li><Text>The <b>extension@prompt.security.json</b> file should be pushed to the <b>/Library/Application Support/Mozilla/ManagedStorage/</b> dir of your employees</Text></li>
        </ol>
    )
}

export default FirefoxMac;