/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const SensitiveDataFormStyle = {
    self: css`
        display: flex;
        flex-direction: column;
        gap: 20px;
    `,
    title: css`
        margin-bottom: 5px;
    `,
    noticeText: css`
        margin-bottom: 20px;
    `,
    actionContainer: css`
        display: flex;
        align-items: center;
        gap: 0 20px;
        flex-wrap: wrap;
    `,
    collapseCategory: css`
        display: flex;
        align-items: center;
        gap: 10px;
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid var(--color-black-10);
    `,
    categoryDataTypesContainer: css`
        display: flex;
        flex-direction: column;
        gap: 10px;
        background: var(--color-black-5);
        padding: 20px;
        border-radius: 10px;   
    `,
    collapseCategoryButton: css`
        margin-left: auto;
    `,
    dataTypeContainer: css`
        display: flex;
        align-items: center;
        gap: 20px;
        padding-bottom: 10px;
        margin-bottom: 10px;
        &:not(:last-of-type) {
            border-bottom: 1px solid var(--color-black-10);
        }
    `,
    dataTypeLabelAndDescription: css`
        display: flex;
        flex-direction: column;
        gap: 5px;
    `,
    examplesContainer: css`
        display: flex;
        align-items: center;
        gap: 5px;
        flex-wrap: wrap;
    `,
    example: css`
        display: flex;
        align-items: center;
        gap: 5px;
        background: var(--color-white);
        padding: 5px 10px;
        border: 1px solid var(--color-black-10);
        width: fit-content;
    `,
    radioGroupContainer: css`
        display: flex;
        align-items: center;
        gap: 15px;
    `,
    dataTypesViewSelectContainer: css`
        display: flex;
        gap: 15px;
        align-items: center;
    `,
    dataTypesTitleContainer: css`
        display: flex; 
        align-items: center;
        gap: 0 35px;
        flex-wrap: wrap;
    `,
    dataTypesSearch: css`
        display: flex;
        margin-left: auto;
        width: 250px;
        margin-block: 10px;
    `,
    tableOverflow: css`
        overflow-x: auto;
    `,
    tableContainer: css`
        border-collapse: separate;
        border-spacing: 0;
        min-width: 350px;
        table-layout: auto;
        width: 100%;

        & tr th,
        & tr td {
            border-right: 1px solid var(--color-black-25);
            border-bottom: 1px solid var(--color-black-25);
            padding: 10px;
            vertical-align: middle;
        }

        & tr th:first-child,
        & tr td:first-child {
            border-left: 1px solid var(--color-black-25);
        }
        & tr th {
            text-align: left;
            border-top: solid 1px var(--color-black-25);
        }

        & tr:first-child th:first-child {
            border-top-left-radius: 10px;
        }

        & tr:first-child th:last-child {
            border-top-right-radius: 10px;
        }

        & tr:last-child td:first-child {
            border-bottom-left-radius: 10px;
        }

        & tr:last-child td:last-child {
            border-bottom-right-radius: 10px;
        }
    `,
    checkboxColumn: css`
        display: flex;
        align-items: center;
        min-width: 200px;
    `,
    actionsContainer: css`
        display: flex; 
        align-items: center;
        gap: 35px;
        border-bottom: 1px solid var(--color-black-10);
        padding-bottom: 20px;
    `,
    sensitiveDataKey: css`
        margin-right: 10px;
        word-break: break-all;
    `
}