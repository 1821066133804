import * as _descope_core_js_sdk from "@descope/core-js-sdk";
declare var pendo: any;

export const pendoInitialize = (user: _descope_core_js_sdk.UserResponse) => {
  const script = document.createElement("script");
  script.async = true;
  script.src =
    "https://cdn.pendo.io/agent/static/213cf86a-d7f4-4af3-702f-5aaa6541b33d/pendo.js";
  document.body.appendChild(script);

  script.onload = () => {
    pendo.initialize({
      visitor: {
        id: user.userId,
        email: user.email,
        firstName: user.givenName,
        lastName: user.familyName,
        role: user.roleNames?.join(", "),
        // productDevelopers: PROPERTY_VALUE,
        // productEmployees: PROPERTY_VALUE,
        // productHomegrownApplications: PROPERTY_VALUE, //TODO - need to add this
      },

      account: {
        id: user.userTenants?.map((x) => x.tenantId).join(", "),
        accountName: user.userTenants?.map((x) => x.tenantName).join(", "),
      },
    });
  };
};
