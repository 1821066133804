/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, memo, useMemo } from 'react';
import { AgGridReact, CustomCellRendererProps } from 'ag-grid-react';
import { ColDef, SizeColumnsToFitGridStrategy, SizeColumnsToFitProvidedWidthStrategy, SizeColumnsToContentStrategy, SortChangedEvent, RowClickedEvent, SelectionChangedEvent } from 'ag-grid-community';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { css } from '@emotion/react';
import { PSIconButton, Chips, DropdownSelect, PSError, LogStatus, NoData, PSDialog, Text, SortHeader, IsValid, TextType, ChipsInput, GenAiApplication, Icon, Tooltip } from '../../ui-kit';
import { IconButton, Skeleton } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FalseClassification, { TClassificationBody, TTextType } from './FalseClassification';

import { Action, OperatorKey, FilterOperatorValue, Pagination, Log, SortDirection, SortByFields, TextType as TextTypeEnum, TextRedactionModes, InputMaybe, FilterInput, FilterValuesFields } from '../../gql/generated/graphql';

import { useQuery } from "@tanstack/react-query";
import request, { GraphQLClient } from 'graphql-request';
import { graphql, graphqlEndpoint } from "../../gql";
import { Controller, useForm } from 'react-hook-form';
import { useStateWithParams } from '../../hooks';
import { DateTypeSelection, defaultDates, TPredefinedDateOrTimeSelection, useDateContext } from '../../contexts';
import ViewHiddenText from './ViewHiddenText';
import ActivityMonitorDrawer from './AcitivityMonitorDrawer/ActivityMonitorDrawer';
import { cleanFilter, dateStringISOToFormatted, extractEntityTypesToArray } from '../../utils';
import AutocompleteSelect from './AutocompleteSelect';

const queryConfiguration = graphql(`
    query Configuration {
        configuration {
            id
            textRedactionMode
            protectApiQuota {
                remaining
            }
            loggingFrequencyInSeconds
        }
    }
`);

const logsQuery = graphql(`
    query LogsQuery($filter: FilterInput, $pagination: PaginationInput!, $sort: SortInput) {
        logs(options: {pagination: $pagination, filter: $filter, sort: $sort}) {
            logs {
                action
                id
                isValid
                risk
                text
                textType
                time
                user
                userGroups
                appName
                applicationId
                applicationType
                conversationId
                country
                ipAddress
                latency
                modifiedText
                policyName
                promptResponseId
                systemPrompt
                violatingFindings
                violations
                extensionData
		sourceData
                genAiApplication
                isError
            }
            pagination {
                currentPage
                itemsPerPage
                nextPage
                prevPage
                totalCount
                totalPages
            }
        }
    }
`);

const createPlaceholderData = (rowCount: number): any[] => {
    return Array.from({ length: rowCount }, (_, index) => {
        ;
        return {
            id: `placeholder-${index}`,
        }
    });
};

const sortDirectionEnumToStringMap = {
    [SortDirection.Ascending]: 'asc',
    [SortDirection.Descending]: 'desc'
}

type IGridProps = {
    rowsData: Array<Partial<Log>> | undefined,
    pagination: Pagination | undefined
    currentPage: number
    sortDirection: SortDirection
    sortBy: SortByFields
    rowIndex: number | null
    itemsPerPage: number
    isLoading?: boolean
    isError?: boolean
    isTextHidden?: boolean
    logsAlerts: "alerts" | "all"
    textCacheMap: Map<string, string>
    refetchTableData: () => void;
    setTextCacheMap: React.Dispatch<React.SetStateAction<Map<string, string>>>
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>
    setItemsPerPage: React.Dispatch<React.SetStateAction<number>>
    setSortBy: React.Dispatch<React.SetStateAction<SortByFields>>
    setSortDirection: React.Dispatch<React.SetStateAction<SortDirection>>
    setRowIndex: React.Dispatch<React.SetStateAction<number | null>>
    rowIndexViewTextRequestModal: number | null
    setRowIndexViewTextRequestModal: React.Dispatch<React.SetStateAction<number | null>>

}
declare module 'ag-grid-community' {
    interface ColDef {
        enumFieldSort?: any;
    }
}

const Grid = (props: IGridProps) => {
    const { rowsData, pagination, isLoading, isError, currentPage, itemsPerPage, rowIndex, sortBy, sortDirection, isTextHidden, logsAlerts, textCacheMap, rowIndexViewTextRequestModal, setRowIndexViewTextRequestModal, refetchTableData, setTextCacheMap, setCurrentPage, setItemsPerPage, setSortBy, setSortDirection, setRowIndex } = props;

    const [rowData, setRowData] = useState<Array<Partial<Log>>>(createPlaceholderData(20));

    const [rowIndexTrueClassificationModal, setRowIndexTrueClassificationModal] = useState<number | null>(null);
    const isTrueClassificationModalOpen = rowIndexTrueClassificationModal !== null;

    const [rowIndexFalseClassificationModal, setRowIndexFalseClassificationModal] = useState<number | null>(null);
    const [isFalseClassificationFormValid, setIsFalseClassificationFormValid] = useState(false);
    const [submitFalseClassificationForm, setSubmitFalseClassificationForm] = useState(() => () => { });
    const isFalseClassificationModalOpen = rowIndexFalseClassificationModal !== null;

    const handleFalseClassificationFormStateChange = (isValid: boolean) => {
        setIsFalseClassificationFormValid(isValid);
    };

    const [isViewTextRequestFormValid, setIsViewTextRequestFormValid] = useState(false);
    const [submitViewTextRequestForm, setSubmitViewTextRequestForm] = useState<(...args: any) => string>(() => () => '');

    const isViewTextRequestModalOpen = rowIndexViewTextRequestModal !== null;

    const handleViewTextRequestFormStateChange = (isValid: boolean) => {
        setIsViewTextRequestFormValid(isValid);
    };

    const { date } = useDateContext();

    const copyLinkToClipboard = (activityId: string) => {
        const dateParsed = date.dates.map(x => x.toISOString()).join(',');
        const pageUrl = `${window.location.origin}/activity-monitor?activityId=${activityId}&dateType=absolute&dates=${encodeURIComponent(dateParsed)}`;
        navigator.clipboard.writeText(pageUrl);
        toast.success('Link copied to clipboard!', {
            hideProgressBar: true,
            autoClose: 2000
        });
    }

    const colDefs: Array<ColDef> = useMemo(() => ([
        // { field: "select", headerName: "", checkboxSelection: !isLoading, headerCheckboxSelection: false, resizable: false, pinned: 'left', maxWidth: 65, hide: true },
        {
            field: "time",
            headerName: "TIME",
            valueFormatter: (params) => dateStringISOToFormatted(params.value),
            colSpan: params => params.data?.isError ? 99 : 1,
            flex: 1,
            minWidth: 240,
            maxWidth: 240,
            enumFieldSort: SortByFields.Time,
            headerComponent: SortHeader,
            headerComponentParams: {
                headerName: 'TIME',
                sortDirection: sortBy === SortByFields.Time ? sortDirectionEnumToStringMap[sortDirection] : undefined,
                onClick: () => {
                    if (sortBy === SortByFields.Time) {
                        setSortDirection((prev) => {
                            if (prev === SortDirection.Ascending) return SortDirection.Descending;
                            return SortDirection.Ascending;
                        });
                    } else {
                        setSortBy(SortByFields.Time);
                        setSortDirection(SortDirection.Descending);
                    }
                }
            }
        },
        {
            field: "isValid",
            headerName: "VERDICT",
            cellRenderer: memo((params: CustomCellRendererProps) =>
                <div
                    css={
                        css`
                        margin: auto !important;
                        `
                    }>
                    <IsValid {...params} />
                </div>
            ),
            maxWidth: 100
        },
        {
            field: "action",
            headerName: "ACTION",
            cellRenderer: memo((params: CustomCellRendererProps) =>
                <div css={css`
                    margin: auto !important;
                `}>
                    <LogStatus {...params} />
                </div>
            ),
            valueGetter: (params) => params.data?.action || '',
            maxWidth: 95
        },
        {
            field: "appName",
            headerName: "CONNECTOR NAME",
            maxWidth: isTextHidden ? undefined : 200,
            flex: isTextHidden ? 1 : 0,
        },
        {
            field: "genAiApplication",
            headerName: "GENAI APPLICATION",
            maxWidth: isTextHidden ? undefined : 400,
            flex: isTextHidden ? 1 : 0,
            cellRenderer: memo(GenAiApplication)
        },
        {
            field: "user",
            headerName: "USER",
            maxWidth: isTextHidden ? undefined : 300,
            flex: isTextHidden ? 1 : 0,
        },
        {
            field: "violations",
            headerName: "VIOLATIONS",
            sortable: false,
            cellRenderer: memo(Chips),
            initialHide: true,
            hide: logsAlerts === 'all',
            flex: isTextHidden ? 1 : 0,
        },
        {
            field: "violatingFindings",
            headerName: "VIOLATION FINDINGS",
            sortable: false,
            minWidth: 200,
            valueGetter: (params) => extractEntityTypesToArray(params.data.violatingFindings, params.data.violations),
            cellRenderer: memo(Chips),
            initialHide: true,
            hide: logsAlerts === 'all',
            flex: isTextHidden ? 1 : 0,
        },
        {
            field: "textType", headerName: "TEXT TYPE", cellRenderer: memo((params: CustomCellRendererProps) =>
                <div
                    css={
                        css`
                        margin: auto !important;
                        `
                    }>
                    <TextType {...params} />
                </div>
            ),
            maxWidth: 110,
        },
        {
            field: "text",
            headerName: "TEXT",
            tooltipField: "text",
            minWidth: 400,
            initialHide: true,
            hide: isTextHidden,
            cellRenderer: memo((params: CustomCellRendererProps) => {
                return <Text tooltipDisableInteractive tooltipMaxCharacters={200} tooltipMaxWidth={400} ellipsis>{params.valueFormatted ?? params.value}</Text>
            }),
            flex: 1
        },
        {
            field: "actions",
            headerName: "ACTIONS",
            maxWidth: 140,
            sortable: false,
            resizable: false,
            pinned: 'right',
            cellRenderer: memo((params: CustomCellRendererProps & { showViewText: boolean }) => (
                <div css={
                    css`
                    display: flex;
                    margin: auto !important;
                    gap: 12px;
                    `
                }>
                    <PSIconButton onClick={() => setRowIndexTrueClassificationModal(params.node.rowIndex)} iconName='PSThumbUpIcon' iconSize={20} tooltipLabel={`True ${params.data.isValid ? 'log' : 'alert'} classification`} />
                    <PSIconButton onClick={() => setRowIndexFalseClassificationModal(params.node.rowIndex)} iconName='PSThumbDownIcon' iconSize={20} tooltipLabel={`False ${params.data.isValid ? 'log' : 'alert'} classification`} />
                    <PSIconButton onClick={() => copyLinkToClipboard(params.data.id)} iconName='ContentCopyRounded' iconSize={20} tooltipLabel={`Copy ${params.data.isValid ? 'log' : 'alert'} link`} />
                </div >
            )),
            cellRendererParams: {
                showViewText: isTextHidden,
            }
        },
    ]),
        [isLoading, isTextHidden, logsAlerts, rowIndex, textCacheMap, sortBy, sortDirection]);

    const autoSizeStrategy: SizeColumnsToFitGridStrategy | SizeColumnsToFitProvidedWidthStrategy | SizeColumnsToContentStrategy = {
        type: 'fitGridWidth',
        columnLimits: [
            {
                colId: 'select',
                maxWidth: 65,
                minWidth: 65
            }
        ],
    }

    const defaultColDef: ColDef = React.useMemo(() => {
        return {
            sortable: false,
            filter: false,
            resizable: false,
            rowDrag: false,
            suppressMovable: true,
            minWidth: 150,
            cellRenderer: (params: CustomCellRendererProps) => {
                if (params.data?.isError) return <PSError direction='row' message='row load failed' />
                return <Text ellipsis>{params.valueFormatted ?? params.value}</Text>
            },
            cellRendererSelector: (params) => {
                if (params.data.id.startsWith('placeholder')) {
                    return {
                        component: () => <Skeleton variant='text' animation='pulse' height={20} width={'100%'} className='loading-skeleton' />
                    }
                }
            },
            cellStyle: (params) => {
                if (params.rowIndex === rowIndex) {
                    return { background: 'var(--color-black-10)' }
                } else {
                    return { background: 'transparent' }
                }
            }
        }
    }, [rowIndex])


    useEffect(() => {
        if (isLoading) {
            setRowData(createPlaceholderData(20));
            return;
        }

        if (rowsData) {
            setRowData(rowsData);
        }
    }, [rowsData, isLoading])

    const paginationFrom = useMemo(() => {
        return pagination?.totalCount === 0 ? 0 : (pagination!.currentPage - 1) * pagination!.itemsPerPage + 1;
    }, [pagination])

    const paginationTo = useMemo(() => {
        return Math.min(pagination!.currentPage * pagination!.itemsPerPage, pagination!.totalCount);
    }, [pagination])

    const handleSortChanged = (event: SortChangedEvent<Log>) => {
        const columnsChanged = event.columns;

        if (!columnsChanged) return;
        if (columnsChanged.length === 0) return;

        const columnToSort = columnsChanged[0];

        const sortDirection = columnToSort.getSort();
        const sortBy = columnToSort.getColDef().enumFieldSort as SortByFields;

        const SortDirectionMap = {
            'asc': SortDirection.Ascending,
            'desc': SortDirection.Descending
        }

        setSortBy(sortBy);
        setSortDirection(SortDirectionMap[sortDirection || 'desc']);
        setCurrentPage(1);
    }


    const handleRowClick = (event: RowClickedEvent) => {
        if (isLoading) return;
        if ((event?.event?.target as HTMLElement).closest('[col-id="actions"]')) return;
        if ((event?.event?.target as HTMLElement).closest('[col-id="select"]')) return;
        if (event.data?.isError) return;
        setRowIndex(event.rowIndex);
    }

    const gridApiRef = React.useRef<AgGridReact>(null);

    const [selectedRows, setSelectedRows] = useState<Array<Log>>([]);

    const handleSelectionChanged = (event: SelectionChangedEvent<Log>) => {
        const selectedNodes = event.api.getSelectedNodes();
        const selectedData = selectedNodes.map((node: any) => node.data);
        setSelectedRows(selectedData);
    }

    useEffect(() => {
        if (!gridApiRef.current) return;
        if (!isError) return;
        setRowData([]);
        gridApiRef?.current?.api?.showNoRowsOverlay();
    }, [gridApiRef, isError])


    useEffect(() => {
        gridApiRef.current?.api?.sizeColumnsToFit();
    }, [isLoading, isTextHidden, logsAlerts, rowIndex, textCacheMap, sortBy, sortDirection])

    const fetchReportTrueClassification = async () => {

        const body: TClassificationBody = {
            classification: `True-${rowData[rowIndexTrueClassificationModal!]?.isValid ? 'positive' : 'negative'}`,
            classificationType: null,
            flipClassification: false,
            textType: rowData[rowIndexTrueClassificationModal!]?.textType!.toLowerCase() as TTextType,
            promptResponseId: rowData[rowIndexTrueClassificationModal!]?.promptResponseId!,
        };

        const request = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body)
        }

        const response = await fetch('/api/be/report-classification', request);
        if (!response.ok) {
            throw new Error('Error reporting classification.');
        }

        toast.success(`The classification was successfully reported.`);
        refetchTableData();
    }


    return (
        <>
            <AgGridReact
                rowHeight={50}
                ref={gridApiRef}
                css={css`--ag-wrapper-border-radius: 15px 15px 0 0; height: calc(100% - 80px) !important;`}
                className="clickable-rows"
                rowSelection='multiple'
                autoSizeStrategy={autoSizeStrategy}
                onSortChanged={handleSortChanged}
                onRowClicked={handleRowClick}
                defaultColDef={defaultColDef}
                rowData={rowData}
                columnDefs={colDefs}
                animateRows={false}
                tooltipTrigger={undefined}
                tooltipShowDelay={10000000000}
                onSelectionChanged={handleSelectionChanged}
                noRowsOverlayComponent={() => {
                    if (isError) {
                        return <PSError />
                    }
                    return <NoData message='Try expanding your selected date range and removing any filters.' />
                }}
                suppressRowClickSelection
                suppressCellFocus
            />
            <div css={css`display: flex; justify-content: space-between; background: var(--color-white); border-radius: 0 0 15px 15px; padding: 10px 25px; border-top: 1px solid var(--color-black-10);`}>
                {selectedRows.length > 0 && <div css={css`display: flex; align-items: center; gap: 10px;`}>
                    <Text><b>{selectedRows.length}</b> Selected</Text>
                    <div onClick={() => {
                        gridApiRef.current?.api.deselectAll();
                    }}><Text textCss={css`
                        cursor: pointer;
                        user-select: none;
                        &:hover {
                            color: var(--color-blue-dark);
                        }
                        &:active {
                            color: var(--color-blue-light);
                        }
                    `} color='blue'>Unselect All</Text></div>
                </div>}
                <div css={css`display: flex; align-items: center; gap: 10px; margin-left: auto;`}>
                    <Text>Page size:</Text>
                    <DropdownSelect minWidth={80} onChange={(value) => {
                        setItemsPerPage(value);
                    }} value={itemsPerPage} options={[10, 20, 50, 100].map((value) => ({ value, label: value }))} />
                    <Text><b>{paginationFrom}</b> to <b>{paginationTo}</b> of <b>{pagination?.totalCount}</b></Text>
                    <div css={css`display: flex; align-items: center; gap: 5px;`}>
                        <PSIconButton tooltipLabel='Back to first page' iconName='FirstPageRounded' iconSize={22} onClick={() => {
                            setCurrentPage(1);
                        }} disabled={pagination?.currentPage === 1} />
                        <PSIconButton tooltipLabel='Previous page' iconName='ArrowBackIosRounded' iconSize={22} onClick={() => {
                            setCurrentPage((prev) => {
                                if (prev === 1) return 1;
                                return prev - 1
                            });
                        }} disabled={pagination?.currentPage === 1 || !pagination?.prevPage} />
                        <Text>Page <b>{pagination?.totalCount === 0 ? 0 : currentPage}</b> of <b>{pagination?.totalPages}</b></Text>
                        <PSIconButton tooltipLabel='Next page' iconName='ArrowForwardIosRounded' iconSize={22} onClick={() => {
                            setCurrentPage((prev) => {
                                if (prev === pagination?.totalPages) return pagination?.totalPages!;
                                return prev + 1
                            });
                        }} disabled={!pagination?.nextPage || pagination?.currentPage === pagination?.totalPages} />
                    </div>
                </div>
            </div>
            <PSDialog
                title={`True ${rowData[rowIndexTrueClassificationModal!]?.isValid ? 'Log' : 'Alert'} Classification`}
                open={isTrueClassificationModalOpen}
                onClose={() => setRowIndexTrueClassificationModal(null)}
                action={() => fetchReportTrueClassification()}
            >
                <Text color='black'>Are you sure you want to report true detection of this {rowData[rowIndexTrueClassificationModal!]?.isValid ? 'log' : 'alert'}?</Text>
            </PSDialog>
            <PSDialog
                title={`False ${rowData[rowIndexFalseClassificationModal!]?.isValid ? 'Log' : 'Alert'} Classification`}
                open={isFalseClassificationModalOpen}
                onClose={() => setRowIndexFalseClassificationModal(null)}
                action={submitFalseClassificationForm}
                isActionDisabled={!isFalseClassificationFormValid}
            >
                <FalseClassification
                    promptResponseId={rowData[rowIndexFalseClassificationModal!]?.promptResponseId!}
                    appName={rowData[rowIndexFalseClassificationModal!]?.genAiApplication?.domain || rowData[rowIndexFalseClassificationModal!]?.appName!}
                    textType={rowData[rowIndexFalseClassificationModal!]?.textType!.toLowerCase() as TTextType}
                    applicationType={rowData[rowIndexFalseClassificationModal!]?.applicationType!}
                    isValid={rowData[rowIndexFalseClassificationModal!]?.isValid!}
                    onFormStateChange={handleFalseClassificationFormStateChange}
                    setSubmitForm={setSubmitFalseClassificationForm}
                    refetchTableData={refetchTableData}
                />
            </PSDialog>
            <PSDialog
                title={`Request to View ${rowData[rowIndexViewTextRequestModal!]?.textType}`}
                actionButtonText='Send'
                open={isViewTextRequestModalOpen}
                onClose={() => setRowIndexViewTextRequestModal(null)}
                action={submitViewTextRequestForm}
                isActionDisabled={!isViewTextRequestFormValid}
            >
                <ViewHiddenText
                    onFormStateChange={handleViewTextRequestFormStateChange}
                    setSubmitForm={setSubmitViewTextRequestForm}
                    promptResponseId={rowData[rowIndexViewTextRequestModal!]?.promptResponseId!}
                    textType={rowData[rowIndexViewTextRequestModal!]?.textType!.toLowerCase() as TTextType}
                    setTextCacheMap={setTextCacheMap}
                    rowIndex={rowIndexViewTextRequestModal!}
                />
            </PSDialog>
        </>
    )

}

type IProps = {};

const AlertsLogsPage: React.FC<IProps> = (props) => {
    const { } = props;

    const [rowIndexViewTextRequestModal, setRowIndexViewTextRequestModal] = useState<number | null>(null);
    const [textCacheMap, setTextCacheMap] = useState<Map<string, string>>(new Map());

    const { control, watch, getValues: getFiltersValues } = useForm<FilterOperatorValue>()

    const [isFirstTimeFetched, setIsFirstTimeFetched] = useState(false);

    const [viewMode, setViewMode] = useStateWithParams<'alerts' | 'all'>('alerts', 'all');
    const [currentPage, setCurrentPage] = useStateWithParams<number>('currentPage', 1);
    const [itemsPerPage, setItemsPerPage] = useStateWithParams<number>('itemsPerPage', 50);
    const [sortBy, setSortBy] = useStateWithParams<SortByFields>('sortBy', SortByFields.Time);
    const [sortDirection, setSortDirection] = useStateWithParams<SortDirection>('sortDirection', SortDirection.Descending);

    const [activityId, setActivityId] = useStateWithParams<Array<string>>('activityId', []);

    const [user, setUser] = useStateWithParams<Array<string>>('user', []);
    const [isUserExclude, setIsUserExclude] = useStateWithParams<boolean>('isUserExclude', false);

    const [appName, setAppName] = useStateWithParams<Array<string>>('appName', []);
    const [isAppNameExclude, setIsAppNameExclude] = useStateWithParams<boolean>('isAppNameExclude', false);

    const [genAiApplicationName, setGenAiApplicationName] = useStateWithParams<Array<string>>('genAiApplicationName', []);
    const [isGenAiApplicationNameExclude, setIsGenAiApplicationNameExclude] = useStateWithParams<boolean>('isGenAiApplicationNameExclude', false);

    const [userGroups, setUserGroups] = useStateWithParams<Array<string>>('userGroups', []);
    const [isUserGroupsExclude, setIsUserGroupsExclude] = useStateWithParams<boolean>('isUserGroupsExclude', false);

    const [violations, setViolations] = useStateWithParams<Array<string>>('violations', []);
    const [isViolationsExclude, setIsViolationsExclude] = useStateWithParams<boolean>('isViolationsExclude', false);

    const [actions, setActions] = useStateWithParams<Array<Action>>('action', []);
    const [isActionsExclude, setIsActionsExclude] = useStateWithParams<boolean>('isActionsExclude', false);

    const [textType, setTextType] = useStateWithParams<Array<TextTypeEnum>>('textType', []);
    const [isTextTypeExclude, setIsTextTypeExclude] = useStateWithParams<boolean>('isTextTypeExclude', false);


    const { date, setDate } = useDateContext();
    const [paramsDate, setParamsDates] = useStateWithParams<[Date, Date]>('dates', date.type === 'absolute' ? date.dates : defaultDates);
    const [paramsDatePeriod, setParamsDatePeriod] = useStateWithParams<TPredefinedDateOrTimeSelection | 'custom'>('period', date.type === 'relative' ? date.period : 'last7days');
    const [paramsDateType, setParamsDateType] = useStateWithParams<keyof typeof DateTypeSelection>('dateType', date.type === 'absolute' ? 'absolute' : 'relative');

    useEffect(() => {
        if (date.type === 'absolute') {
            setParamsDates(date.dates);
            setParamsDatePeriod(null as any);
        } else if (date.type === 'relative') {
            setParamsDatePeriod(date.period);
            setParamsDates(null as any);
        }

        setParamsDateType(date.type);
    }, [date, setParamsDates, setParamsDatePeriod, setParamsDateType])

    const [pagination, setPagination] = useState<Pagination>({
        currentPage,
        itemsPerPage,
        nextPage: null,
        prevPage: null,
        totalCount: 0,
        totalPages: 0
    });



    useEffect(() => {
        if (isFirstTimeFetched) setCurrentPage(1);
    }, [date, itemsPerPage, textType, sortBy, sortDirection, viewMode, actions, activityId, user, appName, userGroups, violations, genAiApplicationName, isActionsExclude, isTextTypeExclude, isUserExclude, isAppNameExclude, isUserGroupsExclude, isViolationsExclude, isGenAiApplicationNameExclude])

    const { data: logsData, refetch, status, isFetching } = useQuery({
        queryKey: ['alerts', currentPage, itemsPerPage, sortBy, sortDirection, JSON.stringify(date.dates), viewMode, isActionsExclude, isTextTypeExclude, isUserExclude, isAppNameExclude, isUserGroupsExclude, isViolationsExclude, isGenAiApplicationNameExclude, getFiltersValues()],
        queryFn: async ({ signal }) => {
            const client = new GraphQLClient(graphqlEndpoint, {
                signal
            });

            const baseFilter: InputMaybe<FilterInput> = {
                key: OperatorKey.And,
                value: [
                    {
                        operator: {
                            key: OperatorKey.And,
                            value: [
                                {
                                    time: {
                                        from: date.dates[0].toISOString(),
                                        to: date.dates[1].toISOString()
                                    },
                                    isValid: viewMode === 'alerts' ? false : undefined,
                                    id: activityId.length > 0 ? activityId : undefined,
                                }
                            ]
                        }
                    },
                    user.length > 0 ? {
                        operator: {
                            key: isUserExclude ? OperatorKey.Not : OperatorKey.And,
                            value: [{
                                user: user
                            }]
                        }
                    } : {},
                    appName.length > 0 ? {
                        operator: {
                            key: isAppNameExclude ? OperatorKey.Not : OperatorKey.And,
                            value: [{
                                appName: appName
                            }]
                        }
                    } : {},
                    userGroups.length > 0 ? {
                        operator: {
                            key: isUserGroupsExclude ? OperatorKey.Not : OperatorKey.And,
                            value: [{
                                userGroups: userGroups
                            }]
                        }
                    } : {},
                    actions.length > 0 ? {
                        operator: {
                            key: isActionsExclude ? OperatorKey.Not : OperatorKey.And,
                            value: [{
                                action: actions
                            }]
                        }
                    } : {},
                    textType.length > 0 ? {
                        operator: {
                            key: isTextTypeExclude ? OperatorKey.Not : OperatorKey.And,
                            value: [{
                                textType: textType
                            }]
                        }
                    } : {},
                    violations.length > 0 ? {
                        operator: {
                            key: isViolationsExclude ? OperatorKey.Not : OperatorKey.And,
                            value: [{
                                violations: violations
                            }]
                        }
                    } : {},
                    genAiApplicationName.length > 0 ? {
                        operator: {
                            key: isGenAiApplicationNameExclude ? OperatorKey.Not : OperatorKey.And,
                            value: [{
                                genAiApplicationName: genAiApplicationName
                            }]
                        }
                    } : {}
                ]
            }

            try {
                const { logs } = await client.request(logsQuery, {
                    filter: cleanFilter(baseFilter),
                    sort: {
                        by: sortBy,
                        direction: sortDirection
                    },
                    pagination: {
                        pageNumber: currentPage,
                        pageSize: itemsPerPage
                    }
                })

                setPagination(logs.pagination);

                if (!isFirstTimeFetched) {
                    setIsFirstTimeFetched(true);
                }

                return logs.logs;
            } catch (error) {
                throw new Error();
            }
        },
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    });

    const { data: configuration } = useQuery(
        {
            queryKey: ["configuration"],
            queryFn: async () => {
                const { configuration } = await request(graphqlEndpoint, queryConfiguration);
                return configuration
            },
        }
    )

    watch();

    const [rowIndex, setRowIndex] = useState<number | null>(null);
    const isDrawerOpen = rowIndex !== null;
    const isLoading = status === 'pending' || isFetching;
    const isError = status === 'error';

    const isTextHidden = configuration?.textRedactionMode === TextRedactionModes.HideAll;

    const handleRefresh = () => {
        if (paramsDateType === 'absolute') {
            setDate({
                type: 'absolute',
                dates: paramsDate,
                period: paramsDatePeriod as 'custom'
            });
        } else if (paramsDateType === 'relative') {
            setDate({
                type: 'relative',
                dates: paramsDate,
                period: paramsDatePeriod as TPredefinedDateOrTimeSelection
            });
        }

        refetch();
    }

    return (
        <div css={css`display: flex; flex-direction: column; height: 100%;`}>
            <div css={css`display: flex; align-items: center; gap: 5px; margin-bottom: 10px; flex-wrap: wrap;`}>
                <Text>Filter By:</Text>
                <DropdownSelect onChange={(newValue) => setViewMode(newValue)} value={viewMode} label='View Mode' options={[
                    { value: 'all', label: 'All' },
                    { value: 'alerts', label: 'Alerts Only' }
                ]} />
                <Controller
                    name="textType"
                    control={control}
                    defaultValue={textType}
                    render={({ field }) => (
                        <DropdownSelect
                            multiple
                            showExclude
                            label='Text Type'
                            value={field.value}
                            options={Object.values(TextTypeEnum).map((value) => ({ value: value, label: value }))}
                            onExcludeChange={setIsTextTypeExclude}
                            isExcludeMode={isTextTypeExclude}
                            onChange={(newValue) => {
                                field.onChange(newValue);
                                setTextType(newValue);
                            }}
                        />
                    )}
                />
                <Controller
                    name="action"
                    control={control}
                    defaultValue={actions}
                    render={({ field }) => (
                        <DropdownSelect
                            multiple
                            showExclude
                            label='Action'
                            value={field.value}
                            options={Object.values(Action).map((value) => ({ value: value, label: value }))}
                            onExcludeChange={setIsActionsExclude}
                            isExcludeMode={isActionsExclude}
                            onChange={(newValue) => {
                                field.onChange(newValue);
                                setActions(newValue);
                            }}
                        />
                    )}
                />
                <Controller
                    name="user"
                    control={control}
                    defaultValue={user}
                    render={({ field }) => (
                        <AutocompleteSelect
                            showExclude
                            inputLabel='User'
                            searchField={FilterValuesFields.User}
                            selectedIds={user}
                            onExcludeChange={setIsUserExclude}
                            onChange={(newValue) => {
                                field.onChange(newValue.map(x => x.value));
                                setUser(newValue.map(x => x.value));
                            }}
                        />
                    )}
                />
                <Controller
                    name="appName"
                    control={control}
                    defaultValue={appName}
                    render={({ field }) => (
                        <AutocompleteSelect
                            showExclude
                            inputLabel='Connector Name'
                            searchField={FilterValuesFields.AppName}
                            selectedIds={appName}
                            onExcludeChange={setIsAppNameExclude}
                            onChange={(newValue) => {
                                field.onChange(newValue.map(x => x.value));
                                setAppName(newValue.map(x => x.value));
                            }}
                        />
                    )}
                />
                <Controller
                    name="genAiApplicationName"
                    control={control}
                    defaultValue={genAiApplicationName}
                    render={({ field }) => (
                        <AutocompleteSelect
                            showExclude
                            inputLabel='GenAI Application'
                            searchField={FilterValuesFields.GenAiApplicationName}
                            selectedIds={genAiApplicationName}
                            onExcludeChange={setIsGenAiApplicationNameExclude}
                            onChange={(newValue) => {
                                field.onChange(newValue.map(x => x.value));
                                setGenAiApplicationName(newValue.map(x => x.value));
                            }}
                        />
                    )}
                />
                <Controller
                    name="userGroups"
                    control={control}
                    defaultValue={userGroups}
                    render={({ field }) => (
                        <AutocompleteSelect
                            showExclude
                            inputLabel='User Groups'
                            searchField={FilterValuesFields.UserGroups}
                            selectedIds={userGroups}
                            onExcludeChange={setIsUserGroupsExclude}
                            onChange={(newValue) => {
                                field.onChange(newValue.map(x => x.value));
                                setUserGroups(newValue.map(x => x.value));
                            }}
                        />
                    )}
                />
                <Controller
                    name="violations"
                    control={control}
                    defaultValue={userGroups}
                    render={({ field }) => (
                        <AutocompleteSelect
                            showExclude
                            inputLabel='Violations'
                            searchField={FilterValuesFields.Violations}
                            selectedIds={violations}
                            onExcludeChange={setIsViolationsExclude}
                            onChange={(newValue) => {
                                field.onChange(newValue.map(x => x.value));
                                setViolations(newValue.map(x => x.value));
                            }}
                        />
                    )}
                />
                <Controller
                    name="id"
                    control={control}
                    defaultValue={activityId}
                    render={({ field }) => (
                        <ChipsInput
                            label='Activity Id'
                            value={field.value!}
                            onChange={(newValue) => {
                                field.onChange(newValue);
                                setActivityId(newValue);
                            }}
                        />
                    )}
                />
                <Tooltip title='Refresh'>
                    <IconButton
                        className='refresh-button'
                        onClick={handleRefresh}
                        size='medium'
                        disabled={isLoading}
                        css={css`border: 1px solid var(--color-black-50);`}
                    >
                        <Icon
                            iconSize={24}
                            iconName='RefreshRounded'
                            css={css`
                                transition: transform 0.5s;
                                transform: ${isLoading ? 'rotate(360deg)' : 'rotate(0deg)'};
                            `}
                        />
                    </IconButton>
                </Tooltip>
            </div>
            <div className="ag-theme-quartz" style={{ flexGrow: 1 }}>
                <Grid
                    rowsData={logsData}
                    pagination={pagination}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    isLoading={isLoading}
                    isError={isError}
                    rowIndex={rowIndex}
                    sortDirection={sortDirection}
                    sortBy={sortBy}
                    isTextHidden={isTextHidden}
                    logsAlerts={viewMode}
                    textCacheMap={textCacheMap}
                    setItemsPerPage={setItemsPerPage}
                    setSortBy={setSortBy}
                    setSortDirection={setSortDirection}
                    setRowIndex={setRowIndex}
                    setTextCacheMap={setTextCacheMap}
                    refetchTableData={refetch}
                    rowIndexViewTextRequestModal={rowIndexViewTextRequestModal}
                    setRowIndexViewTextRequestModal={setRowIndexViewTextRequestModal}
                />
            </div>
            {logsData && rowIndex !== null &&
                <ActivityMonitorDrawer
                    isHidden={isTextHidden}
                    isDrawerOpen={isDrawerOpen}
                    onClose={() => setRowIndex(null)}
                    logData={logsData[rowIndex]}
                    rowIndex={rowIndex}
                    textCacheMap={textCacheMap}
                    setRowIndexViewTextRequestModal={setRowIndexViewTextRequestModal}
                />}
        </div>
    )
}

export default AlertsLogsPage;