import { Box } from '@mui/material';
import { IntegrationViewer } from './IntegrationViewer';

export const ManageIntegration = () => {
    return (
        <Box sx={{ width: '75%', display: 'flex', flexDirection: 'column', marginLeft: '5vh', rowGap: '5vh', marginTop: '5vh'}}>
            <IntegrationViewer />
        </Box>
    )

}