/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { PSTab, PSTabs, Text } from '../../../ui-kit';
import { TConnectorsNames, TConnectorsTypes, TProtectionTextType } from '../Protections';
import { PromptResponsePlaygroundStyle } from './PromptResponsePlayground.css';
import PlaygroundFile from './PlaygroundFile';
import PlaygroundText from './PlaygroundText';

type IProps = {
    policy: TConnectorsTypes;
    appId: string;
    isAdvancedMode: boolean;
    connectorType: TConnectorsNames;
};

const TABS: {
    [K in TProtectionTextType | 'file']: string;
} = {
    prompt: 'Testing Prompts',
    response: 'Testing Responses',
    file: 'Testing Files',
}


const PromptResponsePlayground: React.FC<IProps> = (props) => {
    const { appId, policy, isAdvancedMode, connectorType } = props;

    const [tabValue, setTabValue] = useState<keyof typeof TABS>('prompt');

    return (
        <div>
            <Text variant='bold' textCss={PromptResponsePlaygroundStyle.title}>Playground</Text>
            <PSTabs
                value={tabValue}
                indicatorColor="secondary"
                variant='fullWidth'
                onChange={(_, value) => setTabValue(value)}
                css={PromptResponsePlaygroundStyle.tabs}
            >
                {Object.entries(TABS)
                    .filter(([key]) => connectorType === 'homegrown' || key !== 'response')
                    .map(([key, value]) => (
                        <PSTab key={key} value={key} label={value} />
                    ))}
            </PSTabs>


            <div css={PromptResponsePlaygroundStyle.tabContainer}>
                {tabValue === 'file' && <PlaygroundFile key={tabValue+connectorType} appId={appId} policy={policy} />}
                {tabValue !== 'file' &&
                    <PlaygroundText
                        key={tabValue+connectorType}
                        appId={appId}
                        policy={policy}
                        isAdvancedMode={isAdvancedMode}
                        protectionTextType={tabValue}
                        connectorType={connectorType}
                    />
                }
            </div>
        </div>
    )
}

export default PromptResponsePlayground;