import * as cookie from 'cookie';

const cookieOptions = {
    path: '/'
};

export function setCookie(name: any, value: any) {
    document.cookie = cookie.serialize(name, value, cookieOptions);
}

export function delCookie(name: any) {
    document.cookie = cookie.serialize(name, "", {expires: new Date(0), path: '/'});
}

export function getCookie(name: any) {
    const parsedCookies = cookie.parse(document.cookie);
    return parsedCookies[name];
}