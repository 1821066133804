import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { Dashboard, ManagePolicies, ManageApplications, ManagePolicy, ApplicationIntegration, AdvancedRules } from './routes';
import { useSession, useUser, Descope, useDescope } from '@descope/react-sdk';
import { useLayoutEffect, useState } from 'react';

import { setCurrentTenant, getCurrentTenant, getTenantToDisplay, clearCurrentTenant } from './routes/ManageTenants/tenants';
import { setCookie } from './utils/cookies';
import { ManageConfiguration } from './routes/ManageConfiguration';
import { ManageIntegration } from './routes/ManageIntegration';
import { ExtensionPage, Root } from './routes-new';
import AlertsLogsPage from './routes-new/AlertsLogsPage/AlertsLogsPage';
import { clarityInitialize, pendoInitialize } from './external-tools';
import { Error404 } from './routes-new/ErrorPages';
import { Developers, DevelopersDeployments, DevelopersPolicies, Employees, EmployeesDeployment, EmployeesExtensionSettings, EmployeesGenAiApplications, EmployeesPolicies, HomegrownApplications, HomegrownApplicationsDeployments, HomegrownApplicationsPolicies } from './routes-new/Manage';

const Router = () => {
    const { isAuthenticated, isSessionLoading, sessionToken } = useSession()
    const { user, isUserLoading } = useUser()
    const { logout } = useDescope()
    const [loggedIn, setLoggedIn] = useState<string | null>(localStorage.getItem("loggedIn"));

    useLayoutEffect((): void => {
        const setTenant = async () => {
            const { tenant } = await getTenantToDisplay(user, null);
            if (tenant === null) {
                await fetch('/api/access/register-logout');
                logout();
                clearCurrentTenant();
                return;
            }
            else if (tenant && getCurrentTenant() !== tenant) {
                setCurrentTenant(tenant);
            }
            if (loggedIn === null) {
                await fetch('/api/access/register-login');
                localStorage.setItem("loggedIn", "");
                setLoggedIn("");
            }
        };
        if (isAuthenticated && !isUserLoading) {
            setCookie('dscsess', sessionToken);
            setTenant();
            if (process.env.NODE_ENV !== 'development') {
                pendoInitialize(user);
                clarityInitialize()
            }
        }
    }, [isAuthenticated, logout, user, isUserLoading, loggedIn, sessionToken]);

    if (isSessionLoading || isUserLoading || (loggedIn === null && isAuthenticated !== false))
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh'
            }}>
                <div>Loading...</div>
            </div>
        );
    if (!isAuthenticated)
        return (
            <div style={{ maxWidth: '600px', marginLeft: 'auto', marginRight: 'auto' }}>
                <Descope flowId="ps-sign-in-passkey" />
            </div>
        )

    const router = createBrowserRouter([
        {
            path: '/',
            element: <Root />,
            errorElement: <Error404 />,
            children: [
                { path: '/', Component: () => <Navigate to="/activity-monitor" relative='path' /> },
                {
                    path: '/appsec-dashboard',
                    element: <Dashboard dashboardType="ps-dash" />,
                    handle: { breadcrumb: "Homegrown Apps Dashboard" }
                },
                {
                    index: true,
                    path: "/activity-monitor",
                    element: <AlertsLogsPage />,
                    handle: { breadcrumb: 'Activity Monitor', showDateRange: true },
                },
                {
                    path: "/extension-new",
                    element: <ExtensionPage />,
                    handle: { breadcrumb: 'Extension New (Beta)', showDateRange: true },
                },
                {
                    path: "/extension",
                    element: <Dashboard dashboardType="ps-dash-extension" />,
                    handle: { breadcrumb: 'Employees Dashboard' },
                },
                {
                    path: "/code-assistant",
                    element: <Dashboard dashboardType="ps-dash-code-assistant" />,
                    handle: { breadcrumb: 'Developers Dashboard' },
                },
                {
                    path: "/alerts",
                    element: <Dashboard dashboardType="ps-alerts" />,
                    handle: { breadcrumb: 'Alerts' },
                },
                {
                    path: "/logs",
                    element: <Dashboard dashboardType="ps-logs" />,
                    handle: { breadcrumb: 'Logs' },
                },
                {
                    path: "/audit",
                    element: <Dashboard dashboardType="ps-audit" />,
                    handle: { breadcrumb: 'Audit' },
                },
                {
                    path: "/manage/policies",
                    element: <ManagePolicies />,
                    handle: { breadcrumb: 'Manage Policies' },
                },
                {
                    path: "/manage/policies/:policyId",
                    element: <ManagePolicy />,
                    handle: { breadcrumb: 'Policy Details' },
                },
                {
                    path: "/manage/connectors",
                    element: <ManageApplications />,
                    handle: { breadcrumb: 'Manage Connectors' },
                },
                {
                    path: "/manage/connectors/:applicationName/rules",
                    element: <AdvancedRules />,
                    handle: { breadcrumb: 'Connectors Rules' },
                },
                {
                    path: "/manage/connectors/:applicationName/integration",
                    element: <ApplicationIntegration />,
                    handle: { breadcrumb: 'Manage Connector' },
                },
                {
                    path: "/manage/configuration",
                    element: <ManageConfiguration />,
                    handle: { breadcrumb: 'Manage Configuration' },
                },
                {
                    path: "/manage/integrations",
                    element: <ManageIntegration />,
                    handle: { breadcrumb: 'Manage Integrations' },
                },
                {
                    path: "/homegrown-applications/manage/:connectorName",
                    element: <HomegrownApplications />,
                    handle: {
                        breadcrumb: 'Manage Homegrown Applications',
                        shouldShowManageConnector: true,
                        manageConnectorTopBarProps: {
                            connectorFirstUrlPart: 'homegrown-applications',
                            connectorType: 'REGULAR'
                        }
                    },
                    children: [
                        {
                            path: "policies",
                            element: <HomegrownApplicationsPolicies />,
                        },
                        {
                            path: 'deployment',
                            element: <HomegrownApplicationsDeployments />,
                        }
                    ]
                },
                {
                    path: "/employees/manage/:connectorName",
                    element: <Employees />,
                    handle: {
                        breadcrumb: 'Manage Employees',
                        shouldShowManageConnector: true,
                        manageConnectorTopBarProps: {
                            connectorFirstUrlPart: 'employees',
                            connectorType: 'EXTENSION'
                        }
                    },
                    children: [
                        { path: "", Component: () => <Navigate to="policies" /> },
                        {
                            path: "policies",
                            element: <EmployeesPolicies />,
                        },
                        {
                            path: 'deployment',
                            element: <EmployeesDeployment />,
                        },
                        {
                            path: 'extension-settings',
                            element: <EmployeesExtensionSettings />,
                        },
                        {
                            path: 'manage-gen-ai-applications',
                            element: <EmployeesGenAiApplications />,
                        }
                    ]
                },
                {
                    path: "/developers/manage/:connectorName",
                    element: <Developers />,
                    handle: {
                        breadcrumb: 'Manage Developers',
                        shouldShowManageConnector: true,
                        manageConnectorTopBarProps: {
                            connectorFirstUrlPart: 'developers',
                            connectorType: 'CODE_ASSISTANT'
                        }
                    },
                    children: [
                        { path: "", Component: () => <Navigate to="policies" /> },
                        {
                            path: "policies",
                            element: <DevelopersPolicies />,
                        },
                        {
                            path: 'deployment',
                            element: <DevelopersDeployments />,
                        }
                    ]
                },
                {
                    path: "*",
                    element: <Error404 />,
                    handle: { breadcrumb: 'Page Not Found!' },
                }
            ],
        },
    ]);

    return (
        <RouterProvider router={router} />
    )
}

export default Router;