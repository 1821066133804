import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

export const muiTheme = createTheme({
    typography: {
        fontFamily: [
            "DM Sans",
            "sans-serif"
        ].join(','),
        fontSize: 15,
        h1: {
            fontSize: '2.5rem',
            fontWeight: 700,
            lineHeight: 1.2,
            letterSpacing: '-0.01562em',
        },
        h2: {
            fontSize: '2rem',
            fontWeight: 700,
            lineHeight: 1.2,
            letterSpacing: '-0.00833em',
        },
        h3: {
            fontSize: '1.75rem',
            fontWeight: 700,
            lineHeight: 1.2,
            letterSpacing: '0em',
        },
        h4: {
            fontSize: '1.5rem',
            fontWeight: 700,
            lineHeight: 1.2,
            letterSpacing: '0.00735em',
        },
        h5: {
            fontSize: '1.25rem',
            fontWeight: 700,
            lineHeight: 1.2,
            letterSpacing: '0em',
        },
        h6: {
            fontSize: '1rem',
            fontWeight: 700,
            lineHeight: 1.2,
            letterSpacing: '0.0075em',
        },
        subtitle1: {
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight: 1.2,
            letterSpacing: '0.00938em',
        },
        subtitle2: {
            fontSize: '0.875rem',
            fontWeight: 400,
            lineHeight: 1.2,
            letterSpacing: '0.00714em',
        },
        body1: {
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight: 1.2,
            letterSpacing: '0.00938em',
        },
        body2: {
            fontSize: '0.875rem',
            fontWeight: 400,
            lineHeight: 1.2,
            letterSpacing: '0.01786em',
        },
        button: {
            fontSize: '1rem',
            fontWeight: 700,
            lineHeight: 1.2,
            letterSpacing: '0.02857em',
            textTransform: 'none',
        },
        caption: {
            fontSize: '0.75rem',
            fontWeight: 400,
            lineHeight: 1.2,
            letterSpacing: '0.03333em',
        },
        overline: {
            fontSize: '0.75rem',
            fontWeight: 400,
            lineHeight: 1.2,
            letterSpacing: '0.16667em',
        }
    },
    palette: {
        primary: {
            main: '#415FFF',
        },
        secondary: {
            main: '#b56fed',
        },
        error: {
            main: red.A400,
        }
    },
    components: {
        MuiAutocomplete: {
            styleOverrides: {
                listbox: {
                    padding: 0
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: 'var(--color-white)',
                    borderRadius: '10px',
                    boxShadow: '0px 5px 10px 0px rgba(0, 0, 0, 0.15)',
                }
            }
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    borderRadius: 0,
                }
            },
            variants: [
                {
                    props: { variant: 'persistent' },
                    style: {
                        '& .MuiDrawer-paper': {
                            background: 'var(--color-white)',
                            border: 0,
                            boxShadow: '0px 5px 15px 0px rgba(0, 0, 0, 0.10)',
                        }
                    }
                }
            ]
        },
        MuiOutlinedInput: {
            styleOverrides: {
                multiline: {
                    borderRadius: 10,
                },
                sizeSmall: {
                    borderRadius: 25,
                }
            }
        },
        MuiTabs: {
            variants: [
                {
                    props: { variant: 'scrollable' },
                    style: {
                        '& .MuiTab-root': {
                            minWidth: 'fit-content',
                            flex: 1,
                            maxWidth: 'revert',
                        }
                    }
                }
            ]
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    '& .MuiList-root': {
                        padding: 0,
                    }
                }
            }
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    padding: '0.5rem 1rem',
                    '&.Mui-selected': {
                        borderColor: 'var(--color-blue)'
                    }
                },
                sizeSmall: {
                    borderRadius: 25,
                }
            }
        },
        MuiFormGroup: {
            styleOverrides: {
                root: {
                    '&.MuiRadioGroup-row': {
                        gap: 10
                    },
                }
            }
        }
    }
});
