/** @jsxImportSource @emotion/react */
import { Autocomplete, AutocompleteInputChangeReason, Chip, CircularProgress, Popover, Switch, TextField } from '@mui/material';
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import { useQuery } from '@tanstack/react-query';
import { graphql, graphqlEndpoint } from "../../gql";
import { GraphQLClient } from 'graphql-request';
import { FilterInput, FilterValuesFields, InputMaybe, OperatorKey, SearchType } from '../../gql/generated/graphql';
import { Icon, PSIconButton, PSMenuItem, Text } from '../../ui-kit';
import { useDateContext } from '../../contexts';

type TOption = {
  value: string;
  label: string;
  count: number;
};

type IProps = {
  searchField: FilterValuesFields
  inputLabel?: string
  initialFetchCount?: number
  selectedIds?: Array<string>
  onChange?: (value: Array<TOption>) => void
  showExclude?: boolean;
  onExcludeChange?: (exclude: boolean) => void;
  isExcludeMode?: boolean;
};

const fieldsMap: Record<FilterValuesFields, string> = {
  AppName: "appName",
  User: "user",
  UserGroups: "userGroups",
  Id: "id",
  Violations: "violations",
  GenAiApplicationName: "genAiApplicationName",
}

const queryFilter = graphql(`
    query FilterValues($fields: [FilterValuesFields!]!, $filter: FilterInput, $size: Int!) {
        logFilterValues(options: {fields: $fields, size: $size, filter: $filter}) {
            filterValues {
                field
                values {
                    count
                    value
                }
            }
        }
    }
`);

const AutocompleteSelect: React.FC<IProps> = (props) => {
  const { inputLabel, searchField, initialFetchCount = 15, onChange, selectedIds = [], showExclude, onExcludeChange, isExcludeMode } = props;

  const [isExcludeChecked, setIsExcludeChecked] = React.useState<boolean>(isExcludeMode || false);
  const [inputValue, setInputValue] = useState<string>('');
  const [debounceValue, setDebounceValue] = useState<string>('');
  const [selectedOptions, setSelectedOptions] = useState<Array<TOption>>([]);
  const [options, setOptions] = useState<Array<TOption>>([]);
  const [isInputLoading, setIsInputLoading] = useState<boolean>(false);
  const [popoverAnchorEl, setPopoverAnchorEl] = React.useState<null | HTMLElement>(null);
  const { date } = useDateContext()


  const [, cancel] = useDebounce(() => {
    if (inputValue.trim()) {
      setDebounceValue(inputValue);
      setIsInputLoading(true);
    } else {
      setDebounceValue('');
      setIsInputLoading(false);
    }
    return () => cancel();
  }, 200, [inputValue]);

  const handleInputChange = (newValue: string, reason: AutocompleteInputChangeReason) => {
    if (reason === 'clear') {
      setSelectedOptions([]);
      setPopoverAnchorEl(null);
      if (!onChange) return;
      onChange([]);
      return;
    }
    else if (reason === 'input') {
      setIsInputLoading(true);
      setInputValue(newValue);
      if (!newValue.trim()) {
        setIsInputLoading(false);
      }
    }
  }

  const handleDeleteChip = (optionToDelete: TOption) => {
    const deleteGivenOption = (option: TOption) => option.value !== optionToDelete.value;

    setSelectedOptions((currentSelectedOptions) =>
      currentSelectedOptions.filter(deleteGivenOption)
    );
    if (selectedOptions.length === 1) {
      setPopoverAnchorEl(null);
    }

    if (!onChange) return;
    onChange(selectedOptions.filter(deleteGivenOption));
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setPopoverAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);
  }

  const handleValueChange = (event: React.SyntheticEvent, value: any) => {
    setSelectedOptions(value);
  }

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLDivElement> & { defaultMuiPrevented?: boolean }) => {
    if (event.key === 'Backspace' || event.key === 'Delete') {
      event.stopPropagation()
    }
  };

  const onClose = () => {
    if (!onChange) return;
    onChange(selectedOptions);
    handleExcludeChange(isExcludeChecked);
  }

  const handleExcludeChange = (exclude: boolean) => {
    if (onExcludeChange) {
      onExcludeChange(exclude);
    }
  };

  const { status: filterFetchStatus } = useQuery({
    queryKey: [searchField, debounceValue, JSON.stringify(date.dates)],
    queryFn: async ({ signal }) => {
      const client = new GraphQLClient(graphqlEndpoint, { signal });

      const variables: {
        fields: FilterValuesFields | FilterValuesFields[];
        filter?: InputMaybe<FilterInput> | undefined;
        size: number;
      } = {
        fields: [searchField],
        size: initialFetchCount,
        filter: {
          key: OperatorKey.And,
          value: [
            {
              time: {
                from: date.dates[0].toISOString(),
                to: date.dates[1].toISOString(),
              }
            },
          ],
        }
      };

      if (debounceValue !== '') {
        variables.filter?.value.push({
          search: {
            value: debounceValue,
            fields: [fieldsMap[searchField]],
            type: SearchType.Contains,
          },
        })
      }

      try {
        const { logFilterValues } = await client.request(queryFilter, variables);

        const options: Array<TOption> = logFilterValues.filterValues.flatMap(fv => fv.values.map(v => ({ label: v.value, value: v.value, count: v.count })));
        setOptions(options);
        setIsInputLoading(false);
        return options;
      } catch (error) {
        setIsInputLoading(false);
        throw new Error('Failed to fetch filter values');
      }
    },
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    gcTime: Infinity,
    staleTime: 0
  });

  const { status: selectedOptionsFetchStatus } = useQuery({
    queryKey: [searchField, 'selectedIds'],
    queryFn: async ({ signal }) => {
      if (selectedIds?.length === 0) {
        setSelectedOptions([]);
        return [];
      }

      const client = new GraphQLClient(graphqlEndpoint, { signal });

      const variables: {
        fields: FilterValuesFields | FilterValuesFields[];
        filter?: InputMaybe<FilterInput> | undefined;
        size: number;
      } = {
        fields: [searchField],
        size: selectedIds.length,
        filter: {
          key: OperatorKey.And,
          value: [
            {
              [fieldsMap[searchField]]: selectedIds,
              time: {
                from: date.dates[0].toISOString(),
                to: date.dates[1].toISOString(),
              }
            },
          ],
        }
      };

      try {
        const { logFilterValues } = await client.request(queryFilter, variables);
        const options: Array<TOption> = logFilterValues.filterValues.flatMap(fv => fv.values.map(v => ({ label: v.value, value: v.value, count: v.count })));
        setSelectedOptions(options);
        return options;
      } catch (error) {
        throw new Error('Failed to fetch filter values');
      }
    },
    retry: false,
  })

  useEffect(() => {
    if (filterFetchStatus !== 'pending') {
      setIsInputLoading(false);
    }
  }, [filterFetchStatus]);

  const isLoading = filterFetchStatus === 'pending' || selectedOptionsFetchStatus === 'pending' || isInputLoading;

  const noOptionsText = () => {
    switch (filterFetchStatus) {
      case 'success':
        return 'No options';
      case 'pending':
        return 'Loading...';
      case 'error':
        return 'Error loading options';
    }
  }

  return (
    <React.Fragment>
      <Autocomplete
        className={`autocomplete-select-${searchField}`}
        css={
          css`
            width: 250px;
            max-height: 400px;
          `
        }
        slotProps={{
          paper: {
            style: {
              backgroundColor: 'var(--color-white)',
              borderRadius: '10px',
              boxShadow: '0px 5px 10px 0px rgba(0, 0, 0, 0.15)',
            },
            sx: {
              '& .MuiList-root': {
                padding: '0',
              }
            },
          },
        }}
        size='small'
        multiple
        disableCloseOnSelect
        options={options}
        loading={isLoading}
        value={selectedOptions}
        inputValue={inputValue}
        noOptionsText={noOptionsText()}
        onChange={handleValueChange}
        onClose={onClose}
        onInputChange={(event, newInputValue, reason) => handleInputChange(newInputValue, reason)}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        ListboxProps={{
          style: {
            maxHeight: 250,
          }
        }}
        renderOption={(props, option, { selected, index }) => {
          return (
            <React.Fragment key={option.value + index}>
              {index === 0 && showExclude && (
                <div key={'exclude'} style={{ display: 'flex', gap: 5, justifyContent: 'flex-end', alignItems: 'center', padding: 5, zIndex: 1, position: 'sticky', top: 0, background: 'var(--color-white)' }}>
                  <Text variant='small'>Exclude</Text>
                  <Switch
                    checked={isExcludeChecked}
                    defaultChecked
                    size="small"
                    onChange={(_, checked) => {
                      setIsExcludeChecked(checked)
                    }}
                  />
                </div>
              )}
              <PSMenuItem {...props} key={option.value} type='checkbox' selected={selected}>{option.label}</PSMenuItem>
            </React.Fragment>
          )
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            onKeyDown={handleInputKeyDown}
            label={inputLabel}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading && <CircularProgress color="inherit" size={20} />}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        renderTags={(value, getTagProps) => {
          return <>
            <Chip
              variant='outlined'
              size='small'
              label={`${value.length} selected`}
              icon={isExcludeChecked ? <Icon iconName='RemoveCircleTwoTone' iconSize={'small'} color="black-70" /> : undefined}
              onClick={handlePopoverOpen}
            />
          </>
        }}
      />
      <Popover
        css={css`margin: -10px;`}
        anchorEl={popoverAnchorEl}
        open={!!popoverAnchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div css={
          css`
                    display: flex;
                    flex-direction: column;
                    align-items: start;
                    gap: 10px;
                    padding: 10px;
                    padding-left: 15px;
            `
        }>
          <Text>{inputLabel} ({selectedOptions.length})</Text>
          <div css={css`
                            display: flex;
                            flex-direction: column;
                            align-items: start;
                            gap: 5px;
                            max-height: 300px;
                            overflow: auto;
                            padding-right: 10px;
                        `}>
            {selectedOptions.map((option) => (
              <div key={option.value} css={css`display: flex; align-items: center; justify-content: space-between; width: 100%; gap: 10px;`}>
                <Text variant='small'>{option.label}</Text>
                <PSIconButton onClick={() => handleDeleteChip(option)} iconName='HighlightOffRounded' />
              </div>
            ))}
          </div>
        </div>
      </Popover>
    </React.Fragment>
  )
}

export default AutocompleteSelect;