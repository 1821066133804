/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ColorThemeKeys } from "../../styles";

export const GenAiMgmtOnboardingStyle = {
  self: css`
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  `,
  stepper: css`
    width: min(680px, 100%);
    margin-inline: auto;
    padding-bottom: 20px;
  `,
  stepContainer: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 60px;
    text-align: center;
  `,
  contentContainer: css`
    flex: 1;
    overflow-y: auto;
    padding-bottom: 20px;
  `,
  securityApproachContainer: css`
    display: flex;
    gap: 20px;
    margin-top: 30px;
    align-items: center;
    justify-content: center;
  `,
  summaryContainer: css`
    border-radius: 20px;
    padding: 30px;
    background: var(--color-white);
    margin-inline: auto;
    margin-top: 50px;
    width: min(480px, 100%);
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
  `,
  onboardingSummaryText: css`
    padding-bottom: 20px;
    border-bottom: 1px solid var(--color-blue);
    margin-bottom: 20px;
  `,
  headerSummaryTextContainer: css`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
  `,
  selectedText: css`
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--color-black-50);
  `,
  summaryHeaderLine: css`
    border-bottom: 1px solid var(--color-black-50);
    padding-bottom: 20px;
  `,
  summaryAppsContainer: (color: ColorThemeKeys) => css`
    border-radius: 20px;
    padding: 20px;
    background: var(--color-${color});
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  `,
  itemSummary: css`
    display: flex;
    gap: 10px;
    align-items: center;
    flex-basis: calc(50% - 20px);
    max-width: calc(50% - 20px);
    flex-grow: 0;
    flex-shrink: 0;
    &:only-child {
      flex-basis: 100%;
      max-width: 100%;
    }
  `,
  stepperButtons: css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    margin: 20px auto 0 auto;
  `,
};
