/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const DevelopersPoliciesStyle = {
    self: css`
        display: flex;
        flex-direction: column;
        padding-top: 20px;
        overflow: hidden;
        flex: 1;
    `,
    actionsHeaders: css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 20px;
    `,
    saveCancelContainer: css`
        display: flex;
        align-items: center;
        gap: 10px;
        margin-left: auto;
    `,
    protectionsContainer: css`
        display: flex;
        flex-direction: column;
        gap: 20px;
        flex: 1;
        overflow-y: auto;
        margin-top: 20px;
    `,
    catagoriesContainer: css`
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 10px;

        & > div:only-child {
            display: none;
        }
    `,
    categoryHeader: css`
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-bottom: 10px;
        position: sticky;
        top: 0;
        background: var(--color-background);
        z-index: 2;
    `,
    defaultPolicyContainer: css`
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 5px;
    `,
    footer: css`
        display: flex;
        align-items: center;
        border-top: 1px solid var(--color-black-25);
        padding-top: 20px;
    `
}