/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { PSBox, PSButton, PSCopyText, PSFormControlLabel, PSTab, PSTabs, Text } from '../../../../ui-kit';
import { HomegrownApplicationsAPIStyle } from './HomegrownApplicationsAPI.css';
import CodeMirror from '@uiw/react-codemirror';
import { langs } from '@uiw/codemirror-extensions-langs';
import { useConnectorContext } from '../../../../contexts';
import { PromptSecurityAppSecApiPDF } from '../../../../assets/files';
import { Checkbox } from '@mui/material';
import { censorStringEnd } from '../../../../utils';

const APIS = (domain: string, apiKey: string) => ({
    curl: {
        label: 'cURL',
        code: `curl --location 'https://${domain}/api/protect' \\
--header 'APP-ID: ${apiKey}' \\
--header 'Content-Type: application/json' \\
--data '{"prompt": "your prompt (optional)", "system_prompt": "your system prompt (optional)", "response": "your response (optional)", "user": "your user (optional)"}'`,
        language: langs.shell()
    },
    python: {
        label: 'Python',
        code: `from typing import Optional
import httpx
import json
import asyncio

async def ps_protect_api_async(prompt: str, system_prompt: Optional[str] = None, response: Optional[str] = None, user: Optional[str] = None):
    headers = {
        'APP-ID': '${apiKey}',
        'Content-Type': 'application/json'
    }
    payload = json.dumps({
        'prompt': prompt,
        'system_prompt': system_prompt,
        'response': response,
        'user': user
    })
    async with httpx.AsyncClient() as client:
        ret = await client.post('https://${domain}/api/protect', headers=headers, data=payload)
        return ret.json()

async def main():
    # run in detect only mode without waiting for the response (no latency)
    asyncio.create_task(ps_protect_api_async('prompt', 'system prompt', None, 'user@domain.com'))

    # run in prevention mode (call before sending the prompt to the LLM)
    prompt = 'user prompt'
    ret = await ps_protect_api_async(prompt, 'system prompt', None, 'user@domain.com')
    if ret['result']['prompt']['modified_text']:
        prompt = ret['result']['prompt']['modified_text'] # change the prompt that will be sent

asyncio.run(main())`,
        language: langs.python()
    },
    javascript: {
        label: 'Typescript/Javascript',
        code: `import axios from 'axios';

async function psProtectApiAsync(prompt: string, systemPrompt?: string, response?: string, user?: string) {
    const headers = {
        'APP-ID': '${apiKey}',
        'Content-Type': 'application/json'
    };
    const payload = JSON.stringify({
        'prompt': prompt,
        'system_prompt': systemPrompt,
        'response': response,
        'user': user
    });

    const res = await axios.post('https://${domain}/api/protect', payload, { headers });
    return res.data;
}

async function main() {
    // Run in detect only mode without waiting for the response (no latency)
    psProtectApiAsync('prompt', 'system prompt', undefined, 'user@domain.com');

    // Run in prevention mode (call before sending the prompt to the LLM)
    let userPrompt = 'user prompt';
    const ret = await psProtectApiAsync(userPrompt, 'system prompt', undefined, 'user@domain.com');
    if (ret.result?.prompt?.modified_text) {
        userPrompt = ret.result.prompt.modified_text; // Change the prompt that will be sent
    }
}

main();`,
        language: langs.typescript()
    }
})

type IProps = {};

const HomegrownApplicationsAPI: React.FC<IProps> = (props) => {
    const { } = props;

    const { connector } = useConnectorContext();
    const connectorIdCensored = censorStringEnd(connector.id, 12);

    const [showApiKey, setShowApiKey] = useState(false);
    const apiKey = showApiKey ? connector.id : connectorIdCensored;

    const [selectedTabValue, setSelectedTabValue] = useState<keyof ReturnType<typeof APIS>>('curl');
    const selectedTab = APIS(window.location.hostname, apiKey)[selectedTabValue];

    const [isCopied, setIsCopied] = useState(false);

    const handleCopyText = () => {
        navigator.clipboard.writeText(selectedTab.code);
        setIsCopied(true);
    }

    useEffect(() => {
        if (!isCopied) return;
        const timeout = setTimeout(() => setIsCopied(false), 2000);
        return () => clearTimeout(timeout);
    }, [isCopied])

    return (
        <PSBox padding={30}>
            <div css={HomegrownApplicationsAPIStyle.titleContainer}>
                <Text variant='header2'>API</Text>
                <Text inline><a href={PromptSecurityAppSecApiPDF} download="Prompt_Security_AppSec_API.pdf"><Text inline onClick={() => { }}>Click here</Text></a> to download the API user guide</Text>
            </div>


            <div css={HomegrownApplicationsAPIStyle.apiKey}>
                <PSFormControlLabel label='Display API Key:' control={
                    <Checkbox
                        checked={showApiKey}
                        onChange={(e) => setShowApiKey(e.target.checked)}
                        color='primary'
                    />
                } />
                <PSCopyText label={apiKey} text={connector.id} />
            </div>


            <PSTabs
                value={selectedTabValue}
                onChange={(_, value) => setSelectedTabValue(value)}
            >
                {Object.entries(APIS(window.location.hostname, apiKey)).map(([key, value]) => (
                    <PSTab key={key} value={key} label={value.label} />
                ))}
            </PSTabs>

            <div css={HomegrownApplicationsAPIStyle.codeViewerContainer}>
                <CodeMirror
                    readOnly
                    value={selectedTab.code}
                    theme={'dark'}
                    extensions={[selectedTab.language]}
                />
                <div css={HomegrownApplicationsAPIStyle.copyButton}>
                    <PSButton variant='square' onClick={handleCopyText}>{isCopied ? 'Copied' : 'Copy'}</PSButton>
                </div>
            </div>
        </PSBox>
    )
}

export default HomegrownApplicationsAPI;
