/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import GenAiManage from './GenAiManage';
import { graphql, graphqlEndpoint } from "../../gql";
import { useQuery } from '@tanstack/react-query';
import { GraphQLClient } from 'graphql-request';
import { PSError } from '../../ui-kit';
import { useLocalStorage } from 'react-use';
import GenAiMgmtOnboardingDemo from './GenAiMgmtOnboardingDemo';
import { useUser } from '@descope/react-sdk';
import { SecurityApproach } from '../../gql/generated/graphql';
import GenAiMgmtOnboarding from './GenAiMgmtOnboarding';
import { AdvancedRules } from '../../routes/AdvancedRules';

type IProps = {};

const getOnboardingStatus = graphql(`
    query GetOnboardingSecurityApproachStatus {
        getOnboardingSecurityApproachStatus
    }
`)

const GenAiMgmt: React.FC<IProps> = (props) => {
    const { } = props;

    const { user } = useUser();
    const [isDemo, setIsDemo] = useLocalStorage('isDemo', false);

    useEffect(() => {
        if (window.location.hash === '#isDemo=true') {
            setIsDemo(true);
            window.location.hash = '';
        } else if (window.location.hash === '#isDemo=false') {
            setIsDemo(false);
            window.location.hash = '';
        }
    }, [])

    const {
        data: onboardingSecurityApproachStatus,
        isLoading: isOnboardingSecurityApproachStatusLoading,
        isError: isOnboardingSecurityApproachStatusError
    } = useQuery({
        queryKey: ['onboarding-status'],
        queryFn: async ({ signal }) => {
            const client = new GraphQLClient(graphqlEndpoint, { signal });
            const { getOnboardingSecurityApproachStatus } = await client.request(getOnboardingStatus);
            return getOnboardingSecurityApproachStatus;
        },
    })

    if (isDemo && user.roleNames?.includes('displayDemo')) {
        return <GenAiMgmtOnboardingDemo />
    }

    if (isOnboardingSecurityApproachStatusLoading) {
        return null
    }

    if (isOnboardingSecurityApproachStatusError) {
        return <PSError />
    }

    if (onboardingSecurityApproachStatus === SecurityApproach.Customized) {
        return <AdvancedRules />
    }

    if (onboardingSecurityApproachStatus === null) {
        return <GenAiMgmtOnboarding />;
    }

    return <GenAiManage />;
}

export default GenAiMgmt;