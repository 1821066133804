import React from 'react';
import { ThemeProvider } from '@emotion/react';
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AuthProvider } from '@descope/react-sdk';
import { GlobalStyles } from './styles/globalStyles';
import { LicenseInfo } from '@mui/x-license';
import { ToastContainer, ToastContainerProps } from 'react-toastify';
import { DateContextProvider, NowContextProvider, RightDrawerContextProvider } from './contexts';
import { promptSecurityTheme, muiTheme } from './styles';
import Router from './Router';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

LicenseInfo.setLicenseKey('7aa7d30ef0b0fdcffe0fba001e7581c1Tz04NTQ0NyxFPTE3NDA4MjIxMDMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

const queryClient = new QueryClient();

const toastSettings: ToastContainerProps = {
  position: 'top-center',
  autoClose: 3000,
  closeOnClick: true,
  closeButton: false,
  pauseOnHover: true,
  pauseOnFocusLoss: true,
  newestOnTop: true,
  limit: 5,
}

function App() {
  return (
    <AuthProvider projectId={process.env.REACT_APP_DESCOPE_PROJECT_ID || "P2S79WSQfmRK6pTfwlAH0cSNy4sh"}>
      <QueryClientProvider client={queryClient}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={promptSecurityTheme}>
            <MuiThemeProvider theme={muiTheme}>
              <GlobalStyles />
              <CssBaseline />
              <NowContextProvider>
                <DateContextProvider>
                  <RightDrawerContextProvider>
                    <Router />
                    {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
                  </RightDrawerContextProvider>
                </DateContextProvider>
              </NowContextProvider>
              <ToastContainer {...toastSettings} />
            </MuiThemeProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </QueryClientProvider>
    </AuthProvider>
  );
}

export default App;
