/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const NavigationTopBarStyle = {
  self: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    background: var(--color-background);
    padding: 10px 20px;
    min-height: 59px;
    max-height: 59px;
  `,
};
