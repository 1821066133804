/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const PlaygroundTextStyle = {
    formContainer: css`
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 30px;
        border: 1px solid var(--color-black-10);
        border-radius: 20px;
    `,
    testButton: css`
      align-self: flex-start;
    `,
}