/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const PSIconButtonStyle = {
  self: css`
    display: flex;
    cursor: pointer;
    & .ps-icon-button-icon {
      fill: var(--color-black-50);
    }

    &.disabled {
      & .ps-icon-button-icon {
        fill: var(--color-black-25);
      }
      cursor: default;
      pointer-events: none;
    }

    :not(.disabled) {
      &:hover {
        & .ps-icon-button-icon {
          fill: var(--color-black);
        }
      }

      &:active,
      &.active {
        & .ps-icon-button-icon {
          fill: var(--color-blue);
        }
      }
    }
  `,
};
