/** @jsxImportSource @emotion/react */
import React, { useState, useCallback } from 'react';
import Icon, { IconNames } from '../Icon/Icon';
import Text from '../Text/Text';
import { NavigationLinkItemExpandedStyle } from './NavigationLinkItemExpanded.css';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import Tooltip from '../Tooltip/Tooltip';
import { isPathMatching } from '../../utils';

export type TNavigation = {
    id: string;
    title: string;
    icon?: IconNames;
    currentActivePath?: string;
    path?: string;
    isSelectedPaths?: Array<string>;
    nestedChildren?: TNavigation[];
}

type IProps = {
    id: string;
    isCollapsed?: boolean;
    title: string;
    icon?: IconNames;
    currentActivePath?: string;
    path?: string;
    isSelectedPaths?: Array<string>;
    nestedChildren?: TNavigation[];
    onClick?: () => void;
}

const NavigationLinkItemExpanded: React.FC<IProps> = (props) => {
    const { isCollapsed, title, icon, currentActivePath, nestedChildren, path = '', isSelectedPaths = [], id, onClick } = props;

    const [isExpanded, setIsExpanded] = useState(true);

    const checkIfPathActive = useCallback((givenPath: string | undefined) => {
        if (!currentActivePath) return false;
        if (!givenPath) return false;
        if (givenPath === '/') return currentActivePath === givenPath;

        return isPathMatching(currentActivePath, givenPath);
    }, [currentActivePath]);

    const hasNestedChildrenActive = React.useMemo(() => {
        const checkNestedChildren = (children: TNavigation[]): boolean => {
            return children.some(child => {
                if (child.nestedChildren) return checkNestedChildren(child.nestedChildren);
                return (child.isSelectedPaths || []).some(x => checkIfPathActive(x)) || checkIfPathActive(child.path);
            });
        }
        return nestedChildren ? checkNestedChildren(nestedChildren) : false;
    }, [nestedChildren, currentActivePath]);

    const isNavigationActive = React.useMemo(() => {
        return isSelectedPaths.some(x => checkIfPathActive(x)) || checkIfPathActive(path);
    }, [currentActivePath, path]);

    const handleExpand = () => setIsExpanded(expand => !expand);

    const handleClick = () => {
        if (!onClick) return;
        onClick();
    }

    const NavigationContent = ({ onClick }: { onClick?: () => void }) => {
        return (
            <Tooltip title={isCollapsed && title} disableInteractive placement='right'>
                <li
                    onClick={onClick || handleExpand}
                    css={NavigationLinkItemExpandedStyle.self}
                    className={clsx({ 'selected': isNavigationActive, 'has-nested-active': hasNestedChildrenActive, 'collapsed': isCollapsed, ['navigation-' + id]: true })}
                >
                    {icon && <Icon className={clsx({ 'selected': isNavigationActive })} iconName={icon} iconSize={20} />}
                    {!isCollapsed && <Text variant={isNavigationActive ? 'bold' : 'text'}>{title}</Text>}
                    {nestedChildren && nestedChildren.length > 0 && (
                        <Icon iconName={isExpanded ? 'KeyboardArrowUp' : 'KeyboardArrowDown'} className={'expand-icon'} iconSize={20} />
                    )}
                </li>
            </Tooltip>
        )
    }

    return (
        <React.Fragment>
            {
                nestedChildren && nestedChildren.length > 0 ? (
                    <NavigationContent />
                ) : onClick ? (
                    <NavigationContent onClick={handleClick} />
                ) : path ? (
                    <NavLink css={NavigationLinkItemExpandedStyle.link} to={path}>
                        <NavigationContent />
                    </NavLink>
                ) : (
                    <NavigationContent />
                )
            }
            {isExpanded && nestedChildren && (
                <ul css={NavigationLinkItemExpandedStyle.nested} className={clsx({ 'collapsed': isCollapsed })}>
                    {nestedChildren.map(child => (
                        <NavigationLinkItemExpanded
                            key={child.id}
                            id={child.id}
                            isCollapsed={isCollapsed}
                            title={child.title}
                            icon={child.icon}
                            currentActivePath={currentActivePath}
                            path={child.path}
                            nestedChildren={child.nestedChildren}
                        />
                    ))}
                </ul>
            )}
        </React.Fragment>
    );
}

export default NavigationLinkItemExpanded;