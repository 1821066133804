/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const EmployeesMDMStyle = {
    title: css`
        border-bottom: 1px solid var(--color-black-25);
        padding-bottom: 20px;
        margin-bottom: 20px;
    `,
    sectionDescription: css`
        margin-bottom: 20px;
    `,
    sectionSelectContainer: css`
        gap: 10px;
        flex-wrap: wrap;
    `,
    toggleButton: css`
        border-radius: 10px !important;
        width: 100px;
        height: 100px;
        color: var(--color-blue) !important;
        
        &:not(.Mui-selected) {
            border: 1px solid var(--color-black-25) !important;
            color: var(--color-black-75) !important;
        }
    `,
    iconContainer: css`
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        width: 60px;
        font-size: 30px;
    `,
    sectionContainer: css`
        display: flex;
        flex-direction: column;
        gap: 20px;
        border-bottom: 1px solid var(--color-black-25);
        padding-bottom: 20px;
        margin-bottom: 20px;
        align-items: start;
    `,
}