/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const GenAiManageStyle = {
  self: css`
    display: grid;
    grid-template-columns: minmax(310px, 1fr) 4fr;
    gap: 20px;
    height: 100%;
  `,
  statesContainer: css`
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
  `,
  leftSectionContainer: css`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    gap: 10px;
    padding: 5px;
  `,
  applicationsListContainer: css`
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;
    padding: 5px;
    padding-bottom: 10px;
    overflow-y: "auto";
  `,
  rightSectionContainer: css`
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y: auto;
    padding: 0 10px 10px 10px;
  `,
  applicationsContentContainer: css`
    padding: 40px;
    background: var(--color-white);
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
  `,
};
