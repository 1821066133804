/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const PSApplicationCardStyle = {
  self: css`
    border-radius: 12px;
    border: 1px solid var(--color-black-50);
    background: var(--color-transparent);
    padding: 10px 20px;
    cursor: pointer;
    user-select: none;

    display: flex;
    align-items: center;
    gap: 10px;

    &:not(.disabled) {
      &:not(.active) {
        &:hover {
          border-color: var(--color-black);
        }

        &:active {
          background-color: #ffffffc0;
          border-color: var(--color-blue-light);
        }
      }

      &.active {
        background-color: var(--color-white);
        border-color: var(--color-transparent);
        box-shadow: 0px 4px 10px 0px rgba(97, 54, 253, 0.1);
      }

      &.isSelected {
        background-color: var(--color-background);
        border-color: var(--color-transparent);
      }
    }

    &:focus-visible {
      outline: 2px solid var(--color-blue-light);
      outline-offset: -2px;
    }

    &.disabled {
      cursor: default;
      pointer-events: none;
      border-color: var(--color-black-50);
      color: var(--color-black-50);
      .application-type {
        color: var(--color-black-50);
      }
      img {
        filter: grayscale(100%);
      }
    }
  `,
  customAppLabel: css`
    padding: 5px 10px;
    border-radius: 5px;
    background: var(--color-background);
  `,
};
