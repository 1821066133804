/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ColorThemeKeys } from "../../styles";

export const PSCheckboxCardStyle = {
  self: css`
    border-radius: 13px;
    background: var(--color-white);
    padding: 20px;
    min-height: 130px;
    width: 340px;
    display: flex;
    justify-content: start;
    user-select: none;

    :not(.loading) {
      input:disabled ~ & {
        background: var(--color-transparent);
        border: 1px solid var(--color-black-50);
        color: var(--color-black-50) !important;
      }

      &:not(input:disabled ~ &) {
        input:hover ~ & {
          box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
        }

        input:active ~ & {
          background: var(--color-blue-10);
        }

        input:focus-visible ~ & {
          outline: 1px solid var(--color-blue);
          outline-offset: 5px;
        }
      }
    }
  `,
  selfChecked: (selectedBorderColor: ColorThemeKeys) => css`
    border: 2px solid var(--color-${selectedBorderColor});
    color: var(--color-black) !important;
    box-shadow: 0px 4px 10px 0px rgba(97, 54, 253, 0.1);
  `,
  checkbox: css`
    padding: 0;
  `,
  contentContainer: css`
    display: flex;
    flex-direction: column;
    gap: 15px;
    flex: 1;
    margin-left: 30px;
    overflow: hidden;
  `,
  titleRow: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  `,
  secondaryRow: css`
    display: flex;
    align-items: center;
    gap: 10px;
  `,
  customRow: css`
    display: flex;
    align-items: center;
    gap: 10px;
  `,
  customAppLabel: css`
    padding: 5px 10px;
    border-radius: 5px;
    background: var(--color-background);
  `,
  iconButton: css`
    position: relative;
    z-index: 1;
  `,
};
