import { useQuery } from "@tanstack/react-query";
import { useLocation, useParams } from "react-router-dom";
import { request } from 'graphql-request';
import { graphql, graphqlEndpoint } from '../gql';
import { PolicyTypes } from "../gql/generated/graphql";

const queryPolices = graphql(`
    query Policies {
        policies {
            id
            name
            policyType
        }
    }   
`);

export const ManagePolicy = () => {
    let { policyId } = useParams();
    const { data: policies } = useQuery(
        {
            queryKey: ["policies"],
            queryFn: async () => {
                const { policies } = await request(graphqlEndpoint, queryPolices);
                return policies
            },
            select: (data) => data.find(x => x.id === policyId)
        }
    );
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const expertMode = queryParams.get('expertMode') === 'true';
    const url = `/ps-config-app/?policyId=${policyId}&isExtension=${policies?.policyType === PolicyTypes.Extension}&isCodeAssistant=${policies?.policyType === PolicyTypes.CodeAssistant}&expertMode=${expertMode}`;

    return (
        <iframe title="PsConfig App" style={{ flex: '1 1 auto', border: 0 }} src={url} />
    )
}