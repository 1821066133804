/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const NavigationMenuUserStyle = {
  self: css``,
  
  userButton: css`
    width: fit-content;
  `,

  userDetails: css`
    padding: 14px 15px 15px 15px;
  `,

  userTenants: css`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px;
  `,

  logOutButton: css`
    padding: 12px 15px;

    & .MuiListItemIcon-root {
      min-width: 25px;
    }
  `,

  userMenuPopover: css`
    margin-top: -14px;
  `,
};