/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const TopicsDetectorFormStyle = {
    self: css`
        display: flex;
        flex-direction: column;
        gap: 20px;
    `,
    topicsTableContainer: (rows: number) => css`
        height: ${rows * 50 + 65}px;
        width: 100%;
    `,
    tableAlignRight: css`
        margin-left: auto;
        margin-right: 0;
    `

}