import { setCookie, getCookie } from '../../utils/cookies';
import { UserResponse } from '@descope/core-js-sdk';

export const setCurrentTenant = (newTenant: string): void => {
    setCookie('tenant_name', newTenant);
}

export const clearCurrentTenant = (): void => {
    localStorage.removeItem("loggedIn");
}

export const getCurrentTenant = (): string => {
    return getCookie('tenant_name');
}

export const getEnvTenants = async (user: UserResponse): Promise<string[]> => {
    let tenants: string[] = [];
    if (user && user.userTenants) {
        tenants = user.userTenants.map((tenant) => tenant.tenantName);
    }
    const request = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            tenantNames: tenants
        })
    };

    const response = await fetch('/api/pre-login/get-tenants', request);
    if (response.status === 401) {
        return [];
    }
    const responseText = await response.text();
    return JSON.parse(responseText);
}

type TTenantsInfo = {
    tenant: string | null;
    shouldDisplayTenants: boolean;
}

//DELETE THIS FUNCTION
export const getTenantToDisplay = async (user: UserResponse, envTenants: string[] | null): Promise<TTenantsInfo> => {
    let shouldDisplayTenants: boolean = false;
    let tenant: string | null = null;
    
    if (envTenants === null) {
        envTenants = await getEnvTenants(user);
    }

    if (envTenants !== null && envTenants !== undefined && envTenants.length > 0) {
        tenant = envTenants[0];
        if (envTenants.length > 1) {
            shouldDisplayTenants = true;
        }
    }

    const cookieTenant = getCurrentTenant();
    if (cookieTenant && envTenants.includes(cookieTenant)) {
        return {tenant: cookieTenant, shouldDisplayTenants};
    }
    return {tenant, shouldDisplayTenants};
};