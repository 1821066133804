import { AllProtectionKeys, TConnectorsTypes, TPromptInjectionEngineProtection } from "../Protections";

type BaseProtectRequest = {
    appId: string;
    policy: TConnectorsTypes;
    policy_name?: string;
    system_prompt?: string;
    user?: string;
    user_groups?: Array<string>;
    app_domain?: string;
    os?: string;
    browser?: string;
    copilot_data?: Record<string, any>;
}

export type ProtectPromptRequest = BaseProtectRequest & {
    prompt: string;
}

export type ProtectResponseRequest = BaseProtectRequest & {
    response: string;
}

export type ProtectRequest = ProtectPromptRequest | ProtectResponseRequest;

export const protect = async (request: ProtectRequest): Promise<{
    reason: null,
    result: ProtectResponse,
    status: string;
    ruleInfo: Record<string, unknown>;
}> => {
    const { appId, copilot_data, app_domain, os, browser, ...rest } = request;

    const payload: any = {
        ...rest,
        app_name: "Test App"
    };

    if (app_domain || os || browser) {
        payload.extension_data = {
            extensionVersion: "1.0",
            pageDomain: app_domain,
            platformInfo: {
                os
            },
            browserName: browser
        };
    }

    if (copilot_data) {
        payload.copilot_data = copilot_data;
    }

    if ("prompt" in rest) {
        payload.prompt = rest.prompt;
    }
    else if ("response" in rest) {
        payload.response = rest.response;
        payload.skip_prompt_protection = true;
    }

    const protectResponse = await fetch('/api/protect', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'APP-ID': appId
        },
        body: JSON.stringify(payload)
    });

    return await protectResponse.json();
};

export type ProtectResponse = {
    action: 'log' | 'modify' | 'block';
    conversation_id: string;
    latency: number;
    monitor_only: boolean;
    prompt: PromptResponseDetails;
    prompt_response_id: string;
    response: PromptResponseDetails;
    user: string;
    user_groups: Array<string>;
    totalLatency: number;
    ruleInfo: Record<string, unknown>;
};

export type PromptResponseDetails = {
    action: string;
    findings: Findings;
    latency: LatencyDetails;
    modified_text: null;
    passed: boolean;
    scores: Record<typeof AllProtectionKeys[number], { score: string; threshold?: string }> & Partial<Record<keyof TPromptInjectionEngineProtection, { score: string; }>>;
    violating_findings: string;
    violations: Partial<typeof AllProtectionKeys[number]>;
};

type Findings = Partial<Record<typeof AllProtectionKeys[number], FindingDetail[]>>;

type FindingDetail = {
    characters: number;
    chunks_required: number;
    difficult_words: number;
    letters: number;
    lines: number;
    misspellings: number;
    reading_time: number;
    sentences: number;
    syllables: number;
    text_standard: string;
    tokens: number;
    tokens_limit: number;
    words: number;
};

type LatencyDetails = Partial<Record<typeof AllProtectionKeys[number], number>> & Partial<Record<keyof TPromptInjectionEngineProtection, number>> & { Total?: number };