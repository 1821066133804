/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const GenAiMgmtGlobalSettingsStyle = {
  self: css`
    padding: 40px;
    background: var(--color-white);
    display: flex;
    flex-direction: column;
  `,
  headerContainer: css`
    display: flex;
    align-items: center;
    gap: 10px;
  `,
  rulesContainer: css`
    border-top: 1px solid var(--color-black-25);
    padding-top: 20px;
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--color-black-25);
  `,
  actionsContainer: css`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
    margin-top: 30px;
  `,
  appNotToTrackContainer: css`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  `,
  appsToNotTrackHeader: css`
    display: flex;
    align-items: center;
    gap: 10px;
  `
}