/** @jsxImportSource @emotion/react */
import React from 'react';
import { NavigationLinkItemExpanded } from '../ui-kit';

type IProps = {
    isCollapsed: boolean;
};

const ResourceCenterButton: React.FC<IProps> = (props) => {
    const { isCollapsed } = props;

    const handleResourceCenterClick = () => {
        //@ts-ignore
        pendo?.showGuideById('TJz76U_l3gYGIq55_Sgh0RPmUEY')
    }

    if (process.env.NODE_ENV === 'development') return null;

    return (
        <React.Fragment>
            <NavigationLinkItemExpanded onClick={handleResourceCenterClick} title='Resource Center' icon='SchoolOutlined' isCollapsed={isCollapsed} id="resource-center" />
        </React.Fragment>
    )
}

export default ResourceCenterButton;